import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import config from 'config';
import { LocaleContext } from 'context/locale';
import { buildUrlPath } from 'utils/url';
import { useTranslation } from 'hooks/useTranslation';
import { Direction } from 'types/Utilities';
import styles from './LanguageSwitch.module.scss';
import type { AlternateSlug } from 'types/AlternateSlugs';

type Props = {
  languageSwitchSlugs: { slugs?: AlternateSlug[] | null; prefix?: string };
};

const LanguageSwitch = ({ languageSwitchSlugs }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { locale, t } = useTranslation();
  const countryCode = locale.toUpperCase();
  const { setLocale } = useContext(LocaleContext);
  const { query } = useRouter();
  const { locale: routerLocale } = query;

  const getLocalePath = (locale: string): string => {
    const localeSlugs =
      languageSwitchSlugs.slugs?.filter((entry) => entry.locale === locale) ||
      [];

    // No alternative slug found
    if (!localeSlugs.length) {
      return buildUrlPath(locale);
    }

    return buildUrlPath(
      locale,
      languageSwitchSlugs.prefix || '',
      localeSlugs[0].slug,
    );
  };

  useEffect(() => {
    // Restore correct locale (e.g. browser back button)
    if (locale !== routerLocale) {
      setLocale(routerLocale as string);
    }
  }, [routerLocale]);

  return (
    <div
      onMouseOver={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
      className={styles.container}
    >
      <div className="flex justify-center items-center pointer-events-none">
        <div className="w-5 mr-2 shrink-0">
          {'en' === locale ? (
            <Icon className="w-6 h-4" type="USUKFlag" />
          ) : (
            <Icon type={`Countries.${countryCode}`} />
          )}
        </div>
        <span className="mr-2 text-lg hidden lg:block lg:text-base">
          {countryCode}
        </span>
        <span className="mr-2 text-lg lg:hidden lg:text-base normal-case truncate">
          {t(`langswitch.${locale}`)}
        </span>
        <Icon
          className="text-primary mr-2 w-5 md:mr-1 md:w-3"
          type="Angle"
          direction={Direction.BOTTOM}
        />
      </div>
      <div className={clsx(styles.selectContainer, !isOpen && 'hidden')}>
        <ul className={styles.select}>
          {config.locales.map((l, i) => {
            const localeMain = l.main;
            const localeMainCountryCode = localeMain.toUpperCase();

            const localePath = languageSwitchSlugs
              ? getLocalePath(localeMain)
              : buildUrlPath(locale);

            return (
              <li key={i}>
                <Link href={localePath}>
                  <a
                    className="flex items-center normal-case hover:bg-light-grey text-base p-3 text-primary no-underline"
                    onClick={() => {
                      setLocale(localeMain);
                    }}
                  >
                    {'en' === localeMain ? (
                      <span className="flex items-center">
                        <Icon
                          className="w-6 h-4 mr-4 shrink-0"
                          type="USUKFlag"
                        />
                        {t(`langswitch.${localeMain}`)}
                      </span>
                    ) : (
                      <>
                        {localeMainCountryCode && (
                          <span className="flex items-center">
                            <Icon
                              type={`Countries.${localeMainCountryCode}`}
                              className="w-6 mr-4 h-4 shrink-0"
                            />{' '}
                            {t(`langswitch.${localeMain}`)}
                          </span>
                        )}
                      </>
                    )}
                  </a>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default LanguageSwitch;
