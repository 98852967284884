import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Quno = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="70"
    height="21"
    viewBox="0 0 70 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.10064 11.3627C8.28306 11.1513 8.39342 10.8777 8.39342 10.5797V8.3183H10.6752C10.7592 8.3183 10.8412 8.30986 10.9206 8.29356C11.0795 8.26094 11.2273 8.19796 11.3573 8.1108C11.6827 7.89234 11.8974 7.52317 11.8974 7.10677V4.68398C11.8974 4.26758 11.6827 3.89841 11.3573 3.68023C11.2273 3.59307 11.0795 3.52981 10.9206 3.49719C10.8412 3.48117 10.7592 3.47273 10.6752 3.47273H9.51688C9.10864 3.74574 8.73557 4.06655 8.40505 4.42644C7.41408 5.5061 6.80923 6.94004 6.80923 8.51118C6.80923 8.94445 6.85519 9.36704 6.94257 9.77501C7.00101 10.0466 7.07761 10.312 7.17123 10.569C7.26514 10.8263 7.37578 11.0754 7.50231 11.3152C7.56529 11.4353 7.63253 11.5528 7.70346 11.6678C7.75424 11.6431 7.80332 11.6149 7.84985 11.584C7.94319 11.5216 8.02773 11.4471 8.10064 11.3627Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3308 12.7491C17.1771 12.9057 17.0148 13.0538 16.8448 13.1933L15.4391 11.3084C15.165 10.9406 14.6416 10.8627 14.2702 11.1349C14.0385 11.3044 13.9207 11.571 13.9323 11.8364C13.9369 11.9427 13.9619 12.049 14.0087 12.1485C14.0322 12.1985 14.0609 12.2469 14.0952 12.293L15.4187 14.0674C14.6603 14.4009 13.8223 14.5879 12.9408 14.5879C10.7254 14.5879 8.78088 13.4174 7.70338 11.6677C7.63245 11.5527 7.5655 11.4352 7.50223 11.3151C7.3757 11.0753 7.26506 10.8262 7.17115 10.5689C7.07753 10.3119 7.00093 10.0468 6.94277 9.77492C6.85539 9.36723 6.80943 8.94436 6.80943 8.51137C6.80943 6.93995 7.414 5.5063 8.40526 4.42635C8.73549 4.06646 9.10884 3.74594 9.5168 3.47265C10.4953 2.81754 11.6738 2.43459 12.9408 2.43459C16.3217 2.43459 19.0722 5.16075 19.0722 8.51137C19.0722 9.09929 18.9874 9.66808 18.8293 10.2065C18.7664 10.4216 18.6912 10.6322 18.6055 10.8366C18.5193 11.0416 18.4225 11.2406 18.3153 11.4335C18.208 11.6264 18.0909 11.8128 17.9638 11.9922C17.9002 12.0819 17.8344 12.1696 17.7663 12.2556C17.6301 12.4277 17.4846 12.5925 17.3308 12.7491ZM17.8381 14.5249C19.6084 13.1064 20.7432 10.9381 20.7432 8.51137C20.7432 4.24725 17.2429 0.77826 12.9408 0.77826C11.2448 0.77826 9.67482 1.31894 8.39362 2.23328V1.21125C8.39362 0.544894 7.84352 0 7.17115 0H4.72648C4.05411 0 3.50401 0.544894 3.50401 1.21125V3.47265H1.22219C0.550099 3.47265 0 4.01782 0 4.6839V7.10696C0 7.77304 0.550099 8.31822 1.22219 8.31822H3.50401V10.5796C3.50401 11.2457 4.05411 11.7911 4.72648 11.7911H5.87662C7.12434 14.4192 9.82093 16.2442 12.9408 16.2442C14.1948 16.2442 15.3795 15.9476 16.4306 15.4246L19.6365 19.723C19.8005 19.9426 20.0529 20.0588 20.3091 20.0588C20.4816 20.0588 20.6558 20.0062 20.805 19.8968C21.1761 19.6249 21.2547 19.1062 20.9804 18.7387L17.8381 14.5249Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.1213 4.43552C32.5953 4.43552 32.2977 4.73018 32.2977 5.22924V10.4887C32.2977 12.9598 30.2159 14.7739 27.9971 14.7739C26.1442 14.7739 24.4511 13.3228 24.4511 11.2371V5.22924C24.4511 4.73018 24.1081 4.43552 23.6051 4.43552C23.0788 4.43552 22.7812 4.73018 22.7812 5.22924V11.2371C22.7812 14.5017 25.3206 16.3152 27.9741 16.3152C29.7583 16.3152 31.5198 15.5898 32.3434 13.9349V15.3632C32.3434 16.4513 33.9906 16.4288 33.9906 15.3857V5.22924C33.9906 4.73018 33.6473 4.43552 33.1213 4.43552Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.6147 4.34507C40.8302 4.34507 39.069 5.04798 38.2454 6.70262V5.29709C38.2454 4.20899 36.5982 4.23148 36.5982 5.2746V15.4311C36.5982 15.9301 36.9415 16.2248 37.4675 16.2248C37.9938 16.2248 38.2911 15.9301 38.2911 15.4311V10.1716C38.2911 7.70047 40.3729 5.88669 42.5917 5.88669C44.4449 5.88669 46.1374 7.33749 46.1374 9.42316V15.4311C46.1374 15.9301 46.4807 16.2248 46.984 16.2248C47.5103 16.2248 47.8076 15.9301 47.8076 15.4311V9.42316C47.8076 6.15885 45.2685 4.34507 42.6147 4.34507Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.9329 13.3467C58.106 14.2574 56.8073 14.8488 55.5318 14.8488C54.2331 14.8488 52.9812 14.3028 52.1547 13.3467C51.4458 12.5501 51.1155 11.4802 51.1155 10.4561C51.1155 8.40722 52.2727 6.6319 54.2565 6.13084C54.6109 6.03994 55.0596 5.97191 55.5083 5.97191C55.9571 5.97191 56.4295 6.03994 56.8073 6.13084C58.8149 6.6319 59.9721 8.43009 59.9721 10.5015C59.9721 11.4802 59.594 12.5727 58.9329 13.3467ZM61.5228 9.98523C61.3165 7.44605 59.761 5.36038 57.2675 4.65747C56.6729 4.49889 56.1239 4.45362 55.5747 4.45362C55.003 4.45362 54.3854 4.49889 53.8362 4.65747C51.32 5.36038 49.8328 7.46854 49.6042 9.98523C49.5355 11.6854 49.9701 13.1365 50.9997 14.3382C52.1206 15.6757 53.7448 16.3786 55.5747 16.3786C57.3819 16.3786 59.029 15.6757 60.15 14.3382C61.1565 13.1365 61.6141 11.6854 61.5228 9.98523Z"
      fill={color}
    />
  </svg>
);

export default Quno;
