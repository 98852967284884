import type { IconProps } from 'components/ui/Icon';

const BankTransfer = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg
    {...props}
    width="31"
    height="15"
    viewBox="0 0 31 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.16954 8.35705C4.96948 8.35705 6.29937 7.79452 6.29937 6.16208C6.29937 5.05908 5.69175 4.36419 4.37332 4.20977V4.18771C5.21024 3.96711 5.94397 3.39355 5.94397 2.32365C5.94397 1.23168 5.39367 0.371338 3.43322 0.371338H0.142883V8.35705H3.16954ZM1.90843 7.04448V4.95981H2.9861C3.77716 4.95981 4.41918 5.11423 4.41918 6.00766C4.41918 6.87903 3.77716 7.04448 2.81413 7.04448H1.90843ZM1.90843 3.6693V1.68391H2.8256C3.61666 1.68391 4.21282 1.73906 4.21282 2.5994C4.21282 3.52591 3.38737 3.6693 2.56191 3.6693H1.90843Z"
      fill="#155263"
    />
    <path
      d="M9.35988 0.371338L6.64277 8.35705H8.46564L9.10766 6.41577H11.8706L12.5585 8.35705H14.496L11.5611 0.371338H9.35988ZM11.5038 5.05908H9.40574L10.4261 1.97069H10.4719L11.5038 5.05908Z"
      fill="#155263"
    />
    <path
      d="M22.3147 8.35705V0.371338H20.7097V6.02972L17.5684 0.371338H15.4589V8.35705H17.0754V2.72073L20.274 8.35705H22.3147Z"
      fill="#155263"
    />
    <path
      d="M25.7554 0.371338H24.0013V8.35705H25.7554V5.85324L26.5579 5.01496L28.7477 8.35705H30.8113L27.8191 3.69136L30.8572 0.371338H28.977L25.7554 3.79063V0.371338Z"
      fill="#155263"
    />
    <path
      d="M2.3368 14.7423V11.201H3.59472V10.426H0.142883V11.201H1.41273V14.7423H2.3368Z"
      fill="#155263"
    />
    <path
      d="M6.44876 12.8763C6.88993 12.6736 7.19397 12.2801 7.19397 11.6422C7.19397 11.0043 6.83627 10.426 5.76316 10.426H4.08195V14.7423H4.9941V13.1028H5.58431L6.43683 14.7423H7.42648L6.44876 12.8763ZM4.9941 12.3815V11.1653H5.50084C5.98971 11.1653 6.24606 11.3203 6.24606 11.7316C6.24606 12.2205 5.89432 12.3815 5.46507 12.3815H4.9941Z"
      fill="#155263"
    />
    <path
      d="M8.96223 10.426L7.5493 14.7423H8.49722L8.83107 13.693H10.2678L10.6256 14.7423H11.6331L10.1069 10.426H8.96223ZM10.0771 12.9597H8.98608L9.51667 11.2905H9.54052L10.0771 12.9597Z"
      fill="#155263"
    />
    <path
      d="M15.6989 14.7423V10.426H14.8642V13.4844L13.2307 10.426H12.1338V14.7423H12.9744V11.6959L14.6377 14.7423H15.6989Z"
      fill="#155263"
    />
    <path
      d="M17.9233 11.058C18.4301 11.058 18.597 11.3024 18.6328 11.7078L19.4734 11.6124C19.4078 10.587 18.7043 10.3485 17.9352 10.3485C17.0648 10.3485 16.3613 10.8076 16.3613 11.6482C16.3613 13.2817 18.6089 12.6557 18.6089 13.5559C18.6089 13.9554 18.2751 14.0865 17.8935 14.0865C17.2616 14.0865 17.1066 13.7348 17.035 13.3174L16.2004 13.4426C16.2779 14.5634 17.0946 14.8138 17.8697 14.8138C18.7281 14.8138 19.5211 14.4263 19.5211 13.4486C19.5211 11.8628 17.2794 12.4172 17.2794 11.517C17.2794 11.2428 17.4821 11.058 17.9233 11.058Z"
      fill="#155263"
    />
    <path
      d="M21.0455 13.0492H22.7028V12.292H21.0455V11.1951H22.8459V10.426H20.1274V14.7423H21.0455V13.0492Z"
      fill="#155263"
    />
    <path
      d="M24.3407 12.9597H26.0517V12.2086H24.3407V11.1951H26.1591V10.426H23.4226V14.7423H26.2664V13.9673H24.3407V12.9597Z"
      fill="#155263"
    />
    <path
      d="M29.2652 12.8763C29.7063 12.6736 30.0104 12.2801 30.0104 11.6422C30.0104 11.0043 29.6527 10.426 28.5796 10.426H26.8984V14.7423H27.8105V13.1028H28.4007L29.2532 14.7423H30.2429L29.2652 12.8763ZM27.8105 12.3815V11.1653H28.3172C28.8061 11.1653 29.0625 11.3203 29.0625 11.7316C29.0625 12.2205 28.7107 12.3815 28.2815 12.3815H27.8105Z"
      fill="#155263"
    />
  </svg>
);

export default BankTransfer;
