import Link from 'components/ui/Link';

const DoctorsForUkraineBanner = (): JSX.Element => (
  <div className="bg-navy">
    <div className="max-w-screen-xl mx-auto px-3 md:px-4 text-center relative">
      <p className="leading-none py-2 pb-3">
        <Link href={`/en/ukraine`}>
          <a className="mr-2 transition-colors duration-300 ease-in-out text-white font-bold">
            <span className="font-bold" title="Doctors for Ukraine">
              Doctors for Ukraine 🇺🇦
            </span>
          </a>
        </Link>
      </p>
    </div>
  </div>
);

export default DoctorsForUkraineBanner;
