import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Languages = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.80209 15C6.53048 16.7794 7.55587 18.389 8.59325 19.8184C5.70662 19.2611 3.26228 17.4624 1.83782 15L5.80209 15ZM7.98801 15C8.66673 16.4291 9.56388 17.7772 10.5127 19.0526C11.6283 17.6847 12.4842 16.3343 13.0924 15H7.98801ZM13.8235 13H7.21349C6.92784 12.0356 6.76306 11.0364 6.76306 9.99997C6.76306 9.0087 6.899 8.00889 7.17635 6.99997L13.7864 6.99997C14.072 7.96434 14.2368 8.96359 14.2368 9.99997C14.2368 10.9912 14.1009 11.9911 13.8235 13ZM15.2633 15C14.6303 16.6208 13.6937 18.2234 12.4682 19.8063C15.3281 19.2355 17.7478 17.4449 19.1622 15L15.2633 15ZM20.0422 13H15.8872C16.1192 12.0063 16.2368 11.0062 16.2368 9.99997C16.2368 8.95862 16.0987 7.95819 15.8588 6.99997L20.0422 6.99997C20.3396 7.94701 20.5 8.95477 20.5 9.99997C20.5 11.0452 20.3396 12.0529 20.0422 13ZM19.1622 4.99997C17.7377 2.53747 15.2933 0.738782 12.4066 0.181519C13.444 1.61095 14.4694 3.2205 15.1978 4.99997H19.1622ZM13.0119 4.99997C12.3332 3.57083 11.436 2.22277 10.4872 0.947316C9.37154 2.31519 8.51569 3.66567 7.90745 4.99997H13.0119ZM5.73656 4.99997C6.36956 3.3792 7.3062 1.77658 8.53165 0.193637C5.67186 0.764487 3.25214 2.55504 1.83782 4.99997H5.73656ZM0.957802 6.99997C0.660354 7.94701 0.5 8.95477 0.5 9.99997C0.5 11.0452 0.660354 12.0529 0.957802 13H5.14105C4.90122 12.0418 4.76306 11.0413 4.76306 9.99997C4.76306 8.99372 4.88073 7.99361 5.11273 6.99997L0.957802 6.99997Z"
      fill={color}
    />
  </svg>
);

export default Languages;
