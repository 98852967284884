import tailwind from 'utils/breakpoints';

const ORIGINAL_WIDTH = 17;
const ORIGINAL_HEIGHT = 16;

type Props = {
  color?: string;
  rating?: number;
  emptyColor?: string;
  showEmptyStars?: boolean;
  total?: number;
  starWidth?: number;
  starHeight?: number;
  starGap?: number;
  className?: string;
};

const Stars = ({
  color = tailwind.theme.backgroundColor.secondary,
  emptyColor = 'white',
  rating = 0,
  total = 5,
  starWidth = ORIGINAL_WIDTH,
  starHeight = ORIGINAL_HEIGHT,
  starGap = 0,
  showEmptyStars = true,
  className,
}: Props): JSX.Element => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = fullStars !== rating;

  const emptyStars =
    total - fullStars - (hasHalfStar && fullStars < total - 1 ? 1 : 0);

  const visibleStarsNumber = showEmptyStars
    ? total
    : fullStars + (hasHalfStar ? 1 : 0);

  const stars = [];

  for (let i = 0; i < fullStars; i++) {
    stars.push(
      <path
        key={`full-${i}`}
        d="M9.34049 13.3457C8.86693 13.0631 8.27644 13.0631 7.80288 13.3457L4.42587 15.3615C4.04676 15.5877 3.58185 15.2475 3.68286 14.8177L4.56897 11.0473C4.69717 10.5018 4.51063 9.93032 4.08529 9.56551L1.13687 7.03674C0.80025 6.74803 0.978193 6.19653 1.42007 6.159L5.32705 5.82721C5.87935 5.78031 6.36078 5.43264 6.57899 4.9231L8.11206 1.34332C8.28517 0.939109 8.8582 0.939108 9.03131 1.34332L10.5644 4.9231C10.7826 5.43264 11.264 5.78031 11.8163 5.82721L15.7233 6.159C16.1652 6.19653 16.3431 6.74803 16.0065 7.03674L13.0581 9.56551C12.6327 9.93032 12.4462 10.5018 12.5744 11.0473L13.4605 14.8177C13.5615 15.2475 13.0966 15.5877 12.7175 15.3615L9.34049 13.3457Z"
        fill={color}
        stroke={color}
        transform={`translate(${i * starWidth + i * starGap} 0) scale(${
          starWidth / ORIGINAL_WIDTH
        } ${starHeight / ORIGINAL_HEIGHT})`}
      />,
    );
  }

  if (hasHalfStar) {
    stars.push(
      <g
        transform={`translate(${
          fullStars * starWidth + fullStars * starGap
        } 0) scale(${starWidth / ORIGINAL_WIDTH} ${
          starHeight / ORIGINAL_HEIGHT
        })`}
        key={`half-star`}
      >
        <path
          d="M8.3788 13.3457C7.90524 13.0631 7.31475 13.0631 6.84119 13.3457L3.46418 15.3615C3.08507 15.5877 2.62016 15.2475 2.72117 14.8177L3.60728 11.0473C3.73548 10.5018 3.54894 9.93032 3.1236 9.56551L0.175184 7.03674C-0.161436 6.74803 0.0165072 6.19653 0.458384 6.159L4.36536 5.82721C4.91766 5.78031 5.39909 5.43264 5.6173 4.9231L7.15037 1.34332C7.32348 0.939109 7.89651 0.939108 8.06962 1.34332L9.60271 4.9231C9.82091 5.43264 10.3023 5.78031 10.8546 5.82721L14.7616 6.159C15.2035 6.19653 15.3814 6.74803 15.0448 7.03674L12.0964 9.56551C11.671 9.93032 11.4845 10.5018 11.6127 11.0473L12.4988 14.8177C12.5998 15.2475 12.1349 15.5877 11.7558 15.3615L8.3788 13.3457Z"
          fill="white"
          stroke={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.03831 1.18564C7.81494 0.903726 7.34298 0.942898 7.18869 1.30316L5.65562 4.88294C5.43741 5.39248 4.95598 5.74015 4.40368 5.78705L0.496699 6.11884C0.0548221 6.15637 -0.123121 6.70787 0.213499 6.99658L3.16192 9.52535C3.58726 9.89016 3.7738 10.4616 3.6456 11.0071L2.75949 14.7775C2.65848 15.2073 3.12339 15.5475 3.5025 15.3213L6.87951 13.3055C7.23304 13.0946 7.65175 13.0411 8.03831 13.1451V1.18564Z"
          fill={color}
        />
      </g>,
    );
  }

  if (showEmptyStars) {
    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <path
          key={`empty-${i}`}
          d="M9.34049 13.3457C8.86693 13.0631 8.27644 13.0631 7.80288 13.3457L4.42587 15.3615C4.04676 15.5877 3.58185 15.2475 3.68286 14.8177L4.56897 11.0473C4.69717 10.5018 4.51063 9.93032 4.08529 9.56551L1.13687 7.03674C0.80025 6.74803 0.978193 6.19653 1.42007 6.159L5.32705 5.82721C5.87935 5.78031 6.36078 5.43264 6.57899 4.9231L8.11206 1.34332C8.28517 0.939109 8.8582 0.939108 9.03131 1.34332L10.5644 4.9231C10.7826 5.43264 11.264 5.78031 11.8163 5.82721L15.7233 6.159C16.1652 6.19653 16.3431 6.74803 16.0065 7.03674L13.0581 9.56551C12.6327 9.93032 12.4462 10.5018 12.5744 11.0473L13.4605 14.8177C13.5615 15.2475 13.0966 15.5877 12.7175 15.3615L9.34049 13.3457Z"
          fill={emptyColor}
          stroke={color}
          transform={`translate(${
            (fullStars + i + (hasHalfStar ? 1 : 0)) * starWidth +
            (fullStars + i + (hasHalfStar ? 1 : 0)) * starGap
          } 0) scale(${starWidth / ORIGINAL_WIDTH} ${
            starHeight / ORIGINAL_HEIGHT
          })`}
        />,
      );
    }
  }

  return (
    <svg
      className={className}
      viewBox={`0 0 ${
        starWidth * visibleStarsNumber + starGap * (visibleStarsNumber - 1)
      } ${starHeight}`}
      xmlns="http://www.w3.org/2000/svg"
      width={
        starWidth * visibleStarsNumber + starGap * (visibleStarsNumber - 1)
      }
      height={starHeight}
    >
      <defs>
        <linearGradient id="half-star">
          <stop offset="50%" stopColor={color} />
          <stop offset="50%" stopColor={emptyColor} stopOpacity="1" />
        </linearGradient>
      </defs>
      <g>{stars}</g>
    </svg>
  );
};

export default Stars;
