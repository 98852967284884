import type { IconProps } from 'components/ui/Icon';

const FI = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h640v480H0z" fill="#fff" />
    <path d="M0 174.545h640v130.909H0z" fill="#003580" />
    <path d="M175.455 0h130.909v480H175.455z" fill="#003580" />
  </svg>
);

export default FI;
