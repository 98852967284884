import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Logout = ({
  color = iconDefault.color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 14.5V17C12 17.5523 11.5523 18 11 18H2C1.44771 18 1 17.5523 1 17V2C1 1.44772 1.44772 1 2 1H11C11.5523 1 12 1.44772 12 2V4.5"
      stroke={color}
      strokeLinecap="round"
    />
    <path d="M15 9.5H6" stroke={color} strokeLinecap="round" />
    <path d="M9 6L5.5 9.5L9 13" stroke={color} strokeLinecap="round" />
  </svg>
);

export default Logout;
