import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Suitcase = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.931 4.15H13.5517V2.25C13.5517 1.15 12.6207 0.25 11.4828 0.25H6.62069C5.48276 0.25 4.55172 1.15 4.55172 2.25V4.15H2.06897C0.931035 4.15 0 5.05 0 6.15V13L5 13V12C5 11.7239 5.22386 11.5 5.5 11.5C5.77614 11.5 6 11.7239 6 12V13L12 13V12C12 11.7239 12.2239 11.5 12.5 11.5C12.7761 11.5 13 11.7239 13 12V13H18L18 6.25C18 5.05 17.069 4.15 15.931 4.15ZM12 14V15C12 15.2761 12.2239 15.5 12.5 15.5C12.7761 15.5 13 15.2761 13 15V14H18L18 15.75C18 16.85 17.069 17.75 15.931 17.75H2.06897C0.931035 17.75 0 16.85 0 15.75V14L5 14V15C5 15.2761 5.22386 15.5 5.5 15.5C5.77614 15.5 6 15.2761 6 15V14L12 14ZM5.90005 2.25C5.90005 1.75 6.30005 1.45 6.70005 1.45H11.4C11.8 1.45 12.2 1.85 12.2 2.25V4.15H5.90005V2.25Z"
      fill={color}
    />
  </svg>
);

export default Suitcase;
