import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Select from 'react-select';
import {
  ClearRefinements,
  Configure,
  InstantSearch,
  Pagination,
  ScrollTo,
  Stats,
  // eslint-disable-next-line
  // @ts-ignore
} from 'react-instantsearch-dom';
import { Direction } from 'types/Utilities';
import config from 'config';
import Icon from 'components/ui/Icon';
import SlideOut from 'components/ui/SlideOut';
import { searchClient } from 'services/algolia';
import Filters from './Filters';
import CustomHits from './CustomHits';

const translations: Record<
  string,
  Record<
    | 'interfaceLanguage'
    | 'location'
    | 'seeAll'
    | 'speciality'
    | 'filter'
    | 'resetFilters'
    | 'doctorsFound'
    | 'findDoctors',
    string
  >
> = {
  DE: {
    interfaceLanguage: 'Anzeige',
    location: 'Ort',
    seeAll: 'Alle',
    speciality: 'Spezialität',
    filter: 'Filter',
    resetFilters: 'Filter zurücksetzen',
    doctorsFound: 'Ärzte gefunden',
    findDoctors: 'Ärzte finden',
  },
  EN: {
    interfaceLanguage: 'Display',
    location: 'Visit',
    seeAll: 'See all',
    speciality: 'Speciality',
    filter: 'Filter',
    resetFilters: 'Reset filters',
    doctorsFound: 'Doctors found',
    findDoctors: 'Find doctors',
  },
  RU: {
    interfaceLanguage: 'Интерфейс',
    location: 'Место нахождения',
    seeAll: 'Все',
    speciality: 'Специальность',
    filter: 'Фильтры',
    resetFilters: 'сбросить фильтры',
    doctorsFound: 'врача найдено',
    findDoctors: 'Найти докторов',
  },
  UA: {
    interfaceLanguage: 'Iнтерфейс / Interface',
    location: 'Розташування',
    seeAll: 'всі',
    speciality: 'Спеціальність',
    filter: 'фільтри',
    resetFilters: 'скинути фільтри',
    doctorsFound: 'врача найдено',
    findDoctors: 'Знайти докторів ',
  },
};

const languageOptions = [
  { value: 'DE', label: '🇩🇪 Deutsch' },
  { value: 'EN', label: '🇺🇸 English' },
  { value: 'UA', label: '🇺🇦 Украïнський' },
  { value: 'RU', label: '🇷🇺 Русский' },
];

type Query = Record<string, string | undefined>;

type SearchState = {
  query?: string;
  page?: string | number;
  toggle?: Record<string, boolean>;
  menu?: Record<string, string>;
  configure?: {
    hitsPerPage: number;
    aroundLatLng: string;
    getRankingInfo: {
      aroundRadius: string | number;
    };
  };
};

// Record<string, string> is a substitute for InstantSearchProps
type Props = Record<string, string> & {
  resultState: Record<string, string>;
};

const DoctorList = ({ resultState, ...props }: Props): JSX.Element => {
  const DEFAULT_LOCATION = '52.52523401376979, 13.369348354047876';
  const { algolia } = config.defaults;

  const router = useRouter();
  const [searchState, setSearchState] = useState<SearchState>({});
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [zip, setZip] = useState('');
  const [language, setLanguage] = useState('UA');
  const [isReady, setIsReady] = useState(false);
  const [location, setLocation] = useState(DEFAULT_LOCATION);

  const createNewSearchState = (query: Query): void => {
    const queryLanguages = query.l?.split(',') || null;
    const queryPlace = query.pl?.split(',') || null;

    const newSearchState = {
      query: query.s ? decodeURI(query.s) : '',
      page: query.p || 1,
      toggle: {
        ['Language (DE)']: !!queryLanguages?.includes('german'),
        ['Language (EN)']: !!queryLanguages?.includes('english'),
        ['Language (RU)']: !!queryLanguages?.includes('russian'),
        ['Language (UA)']: !!queryLanguages?.includes('ukrainian'),
        ['Treatment (Home)']: !!queryPlace?.includes('home'),
        ['Treatment (Practice)']: !!queryPlace?.includes('practice'),
      },
      menu: {
        ['Specialty (DE)']:
          query.sp && language === 'DE' ? decodeURI(query.sp) : '',
        ['Specialty (EN)']:
          query.sp && language === 'EN' ? decodeURI(query.sp) : '',
        ['Specialty (RU)']:
          query.sp && language === 'RU' ? decodeURI(query.sp) : '',
        ['Specialty (UA)']:
          query.sp && language === 'UA' ? decodeURI(query.sp) : '',
        City: query.c ? decodeURI(query.c) : '',
      },
      configure: {
        hitsPerPage: algolia.hitsPerPage,
        aroundLatLng: location,
        getRankingInfo: {
          aroundRadius: location === DEFAULT_LOCATION ? 'all' : 50000,
        },
      },
    };

    setSearchState(newSearchState);
  };

  useEffect(() => {
    const routerHash = router.asPath.split('#')[1];
    const query: Query = {};
    if (routerHash) {
      const routerHashParts = routerHash.split('&');
      for (const hashPart of routerHashParts) {
        const [key, value] = hashPart.split('=');
        query[key] = value;
      }

      if (query.il) {
        setLanguage(query.il);
      } else {
        setIsReady(true);
      }

      if (query.zip) {
        setZip(query.zip);
        onPostcodeChange(query.zip);
      }

      createNewSearchState(query);
    }
  }, []);

  useEffect(() => {
    const routerHash = router.asPath.split('#')[1];
    const query: Query = {};
    if (routerHash && !isReady) {
      const routerHashParts = routerHash.split('&');
      for (const hashPart of routerHashParts) {
        const [key, value] = hashPart.split('=');
        query[key] = value;
      }

      createNewSearchState(query);

      if (query.il === language) {
        setIsReady(true);
      }
    } else {
      window.location.href = `${
        window.location.href.split('#')[0]
      }#p=1&il=${language}`;
    }
  }, [language]);

  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
          window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "yp22p9q8"
          };
          // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/yp22p9q8'
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/yp22p9q8';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
          `;

    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
      const intercomFrame = document.querySelector('#intercom-frame');
      if (intercomFrame) {
        document.body.removeChild(intercomFrame);
      }

      const intercomContainer = document.querySelector('#intercom-container');
      if (intercomContainer) {
        document.body.removeChild(intercomContainer);
      }

      const intercomCssContainer = document.querySelector(
        '#intercom-css-container',
      );
      if (intercomCssContainer) {
        document.body.removeChild(intercomCssContainer);
      }

      const intercomLightweightApp = document.querySelector(
        '.intercom-lightweight-app',
      );
      if (intercomLightweightApp) {
        document.body.removeChild(intercomLightweightApp);
      }
    };
  }, []);

  const onLanguageChange = (lang: string): void => {
    setLanguage(lang);

    const clearRefinementsButton = document.querySelector<HTMLButtonElement>(
      '.ais-ClearRefinements-button',
    );

    clearRefinementsButton?.click();
  };

  const copyToClipboard = (content: string): void => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(content);
    }
  };

  const onPostcodeChange = (zip: string): void => {
    if (zip.length === 5) {
      const index = searchClient.initIndex('german_zipcodes');

      index.search('', { facetFilters: [`zip:${zip}`] }).then(({ hits }) => {
        const hit = hits[0] as unknown as { lat: number; long: number };
        if (hit) {
          setZip(zip);
          setLocation(`${hit.lat},${hit.long}`);
        } else {
          setZip(zip);
          setLocation(DEFAULT_LOCATION);
        }
      });
    } else if (location !== DEFAULT_LOCATION) {
      setZip('');
      setLocation(DEFAULT_LOCATION);
    }
  };

  return (
    <div className="max-w-screen-xl px-5 md:px-4 mb-6">
      <InstantSearch
        indexName="doctors_for_ukraine"
        resultsState={resultState}
        searchState={searchState}
        searchClient={searchClient}
        onSearchStateChange={(searchState: SearchState) => {
          let newHash = `p=${searchState.page}`;
          let languages = '';
          let place = '';

          const toggle = searchState.toggle || {};
          const menu = searchState.menu || {};

          if (toggle['Language (DE)']) {
            languages += 'german,';
          }

          if (toggle['Language (EN)']) {
            languages += 'english,';
          }

          if (toggle['Language (RU)']) {
            languages += 'russian,';
          }

          if (toggle['Language (UA)']) {
            languages += 'ukrainian,';
          }

          if (languages) {
            newHash += `&l=${languages}`;
          }

          if (toggle['Treatment (Home)']) {
            place += 'home,';
          }

          if (toggle['Treatment (Practice)']) {
            place += 'practice,';
          }

          if (place) {
            newHash += `&pl=${place}`;
          }

          if (searchState.query) {
            newHash += `&s=${searchState.query}`;
          }

          if (menu.City) {
            newHash += `&c=${menu.City}`;
          }

          if (menu[`Specialty (${language})`]) {
            newHash += `&sp=${menu[`Specialty (${language})`]}`;
          }

          if (zip) {
            newHash += `&zip=${zip}`;
          }

          newHash += `&il=${language}`;

          window.location.href = `${
            window.location.href.split('#')[0]
          }#${newHash}`;

          const newSearchState = {
            query: searchState.query || '',
            page: searchState.page || 1,
            toggle: {
              ['Language (DE)']: !!toggle['Language (DE)'],
              ['Language (EN)']: !!toggle['Language (EN)'],
              ['Language (RU)']: !!toggle['Language (RU)'],
              ['Language (UA)']: !!toggle['Language (UA)'],
              ['Treatment (Home)']: !!toggle['Treatment (Home)'],
              ['Treatment (Practice)']: !!toggle['Treatment (Practice)'],
            },
            menu: {
              ['Specialty (DE)']:
                menu['Specialty (DE)'] && language === 'DE'
                  ? menu['Specialty (DE)']
                  : '',
              ['Specialty (EN)']:
                menu['Specialty (EN)'] && language === 'EN'
                  ? menu['Specialty (EN)']
                  : '',
              ['Specialty (RU)']:
                menu['Specialty (RU)'] && language === 'RU'
                  ? menu['Specialty (RU)']
                  : '',
              ['Specialty (UA)']:
                menu['Specialty (UA)'] && language === 'UA'
                  ? menu['Specialty (UA)']
                  : '',
              City: menu.City || '',
            },
          };

          setSearchState(newSearchState);
        }}
        {...props}
      >
        <ScrollTo>
          <Configure
            hitsPerPage={algolia.hitsPerPage}
            aroundLatLng={location}
            getRankingInfo
            aroundRadius={location === DEFAULT_LOCATION ? 'all' : 50000}
          />
        </ScrollTo>
        <SlideOut
          open={isFiltersOpen}
          onClose={() => {
            setIsFiltersOpen(false);
          }}
          placement="right"
          backdrop={false}
          showCloseIcon={false}
          visuallyHidden
        >
          <div className="mx-6 doctors-list">
            <p className="font-bold text-navy uppercase mt-5 mb-8 flex items-center cursor-pointer hover:bg-bluishGrey">
              <Icon
                type="Angle"
                color="#155263"
                className="inline-flex mr-4"
                direction={Direction.LEFT}
                onClick={() => {
                  setIsFiltersOpen(false);
                }}
              />{' '}
              <span>{translations[language].findDoctors}</span>
            </p>
            <Filters
              locale={language}
              onPostcodeChange={onPostcodeChange}
              zip={zip}
            />
          </div>
          <hr className="mt-6" />
          <div className=" mx-6 my-2 mobile-filters-bottom bg-white pb-2">
            <ClearRefinements
              clearsQuery
              translations={{
                reset: translations[language].resetFilters,
              }}
            />
            <button
              className="bg-secondary rounded px-5 py-2 font-netto mt-4 w-full"
              onClick={() => {
                setIsFiltersOpen(false);
              }}
            >
              <Stats
                translations={{
                  stats(nbHits: number) {
                    switch (language) {
                      case 'EN':
                      case 'DE':
                      case 'RU':
                        return `${nbHits} ${translations[language].doctorsFound}`;
                      case 'UA':
                        return `Знайдено ${nbHits} лікаря`;
                      default:
                        return '';
                    }
                  },
                }}
              />
            </button>
          </div>
        </SlideOut>
        <div className="md:grid md:grid-cols-6 md:gap-x-12 lg:gap-x-32 doctors-list">
          <div className="md:col-span-2 md:block">
            <p className="font-bold text-navy text-lg md:text-base uppercase mt-9 mb-4 md:mt-0 md:mb-2">
              {translations[language].interfaceLanguage}
            </p>
            <Select
              name="language"
              options={languageOptions}
              className="mb-6"
              value={languageOptions.find((o) => o.value === language)}
              classNamePrefix={'dl-language'}
              onChange={(element) => {
                onLanguageChange(element?.value || '');
              }}
            />

            <div className="hidden md:block">
              <Filters
                locale={language}
                onPostcodeChange={onPostcodeChange}
                zip={zip}
              />
              <div className="text-right">
                <ClearRefinements
                  clearsQuery
                  translations={{
                    reset: translations[language].resetFilters,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="md:col-span-4 mt-4 md:mt-0">
            <div className="mb-4 md:mb-0">
              <p className="md:hidden text-center mb-4">
                <span
                  className="rounded border py-3 px-5 text-sm cursor-pointer block hover:bg-bluishGrey"
                  onClick={() => {
                    setIsFiltersOpen(true);
                  }}
                >
                  <Icon
                    type="Filters"
                    color="#155263"
                    className="inline-flex mr-4"
                  />
                  {translations[language].findDoctors}
                </span>
              </p>
              <div className="font-bold text-navy uppercase mb-4 md:mb-12 text-base">
                <Stats
                  translations={{
                    stats(nbHits: number) {
                      switch (language) {
                        case 'EN':
                        case 'DE':
                        case 'RU':
                          return `${nbHits} ${translations[language].doctorsFound}`;
                        case 'UA':
                          return `Знайдено ${nbHits} лікаря`;
                        default:
                          return '';
                      }
                    },
                  }}
                />
              </div>
            </div>
            <div className="overflow-hidden relative z-9"></div>
            <CustomHits
              locale={language}
              copyToClipboard={copyToClipboard}
              location={location}
            />
            <Pagination
              padding={algolia.paginationPadding}
              showFirst={false}
              translations={{
                next: (
                  <Icon
                    type="Angle"
                    color="#585a51"
                    className="inline-flex"
                    direction={Direction.RIGHT}
                  />
                ),
                previous: (
                  <Icon
                    type="Angle"
                    color="#585a51"
                    className="inline-flex"
                    direction={Direction.LEFT}
                  />
                ),
              }}
            />
          </div>
        </div>
      </InstantSearch>
    </div>
  );
};

export default DoctorList;
