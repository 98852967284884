import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Oncology = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15.35" cy="9.35" r="2.85" stroke={color} strokeWidth="3" />
    <circle cx="25.6166" cy="23.75" r="2.85" stroke={color} strokeWidth="3" />
    <circle cx="27.0666" cy="36.8" r="2.36667" stroke={color} strokeWidth="3" />
    <circle cx="35.7667" cy="22.3" r="1.5" stroke={color} strokeWidth="2.8" />
    <circle cx="34.7999" cy="31" r="1.5" stroke={color} strokeWidth="2.8" />
    <circle
      cx="20.3"
      cy="30.0333"
      r="1.5"
      fill={color}
      stroke={color}
      strokeWidth="0.866667"
    />
    <path
      d="M41.3501 5.85C41.3501 7.42401 40.0741 8.7 38.5001 8.7C36.9261 8.7 35.6501 7.42401 35.6501 5.85C35.6501 4.27599 36.9261 3 38.5001 3C40.0741 3 41.3501 4.27599 41.3501 5.85Z"
      stroke={color}
      strokeWidth="3"
    />
    <circle cx="53.65" cy="26.65" r="2.85" stroke={color} strokeWidth="3" />
    <circle cx="43.35" cy="50.35" r="2.85" stroke={color} strokeWidth="3" />
    <circle cx="18.35" cy="52.35" r="2.85" stroke={color} strokeWidth="3" />
    <circle cx="4.35" cy="32.35" r="2.85" stroke={color} strokeWidth="3" />
    <line
      x1="8.12464"
      y1="31.3037"
      x2="15.7703"
      y2="30.6086"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="41.9581"
      y1="27.437"
      x2="49.6038"
      y2="26.7419"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="18.0685"
      y1="12.4703"
      x2="21.737"
      y2="18.2981"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="37.6493"
      y1="9.75693"
      x2="34.8237"
      y2="16.6479"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="23.1493"
      y1="41.6569"
      x2="20.3237"
      y2="48.5479"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="36.8802"
      y1="40.116"
      x2="41.0161"
      y2="46.3199"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M38.6668 16.0154C35.8394 13.8917 32.3251 12.6333 28.5168 12.6333C19.1739 12.6333 11.6001 20.2072 11.6001 29.55C11.6001 38.8928 19.1739 46.4667 28.5168 46.4667C37.8596 46.4667 45.4334 38.8928 45.4334 29.55C45.4334 27.4011 45.0327 25.3457 44.302 23.4546"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export default Oncology;
