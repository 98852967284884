import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Document = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h18v18H0z" />
      <g stroke={color} strokeLinecap="round" strokeLinejoin="round">
        <path d="M15.91 13.217V1.464C15.91.88 15.426.4 14.834.4H3.076C2.484.4 2 .88 2 1.464v15.054c0 .585.484 1.064 1.076 1.064h8.539" />
        <path d="M15.91 13.09h-4.092v4.092m.132.368l3.85-4.05M5.273 4.09h7.363M5.273 7.364h7.363m-7.363 3.272h7.363M5.273 13.91h3.272" />
      </g>
    </g>
  </svg>
);

export default Document;
