import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const HouseAlternate = ({
  color = iconDefault.color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M17.5149 7.79399C17.5143 7.79358 17.5139 7.79303 17.5135 7.79262L10.1709 0.450303C9.85793 0.137192 9.44182 -0.0351562 8.99921 -0.0351562C8.5566 -0.0351562 8.14049 0.137192 7.82738 0.450303L0.488636 7.78891C0.486164 7.79138 0.483555 7.79399 0.481221 7.79646C-0.161481 8.44287 -0.160382 9.49166 0.484379 10.1364C0.778951 10.4311 1.16787 10.6017 1.58384 10.6197C1.60087 10.6213 1.6179 10.6222 1.63506 10.6222H1.92757V16.0255C1.92757 17.0949 2.79769 17.9649 3.86708 17.9649H6.73973C7.03101 17.9649 7.26708 17.7287 7.26708 17.4375V13.2012C7.26708 12.7133 7.66409 12.3164 8.15203 12.3164H9.8464C10.3343 12.3164 10.7312 12.7133 10.7312 13.2012V17.4375C10.7312 17.7287 10.9673 17.9649 11.2586 17.9649H14.1312C15.2007 17.9649 16.0707 17.0949 16.0707 16.0255V10.6222H16.3421C16.7845 10.6222 17.2007 10.4498 17.5139 10.1366C18.1594 9.49083 18.1596 8.44013 17.5149 7.79399ZM16.7681 9.39086C16.6542 9.5047 16.5029 9.56746 16.3421 9.56746H15.5434C15.2521 9.56746 15.016 9.80353 15.016 10.0948V16.0255C15.016 16.5133 14.6191 16.9102 14.1312 16.9102H11.7859V13.2012C11.7859 12.1318 10.9159 11.2617 9.8464 11.2617H8.15203C7.0825 11.2617 6.21239 12.1318 6.21239 13.2012V16.9102H3.86708C3.37928 16.9102 2.98226 16.5133 2.98226 16.0255V10.0948C2.98226 9.80353 2.74619 9.56746 2.45492 9.56746H1.66994C1.6617 9.56691 1.6536 9.5665 1.64522 9.56636C1.48812 9.56362 1.34076 9.50127 1.23035 9.39072C0.995519 9.15589 0.995519 8.7737 1.23035 8.53873C1.23049 8.53873 1.23049 8.53859 1.23063 8.53845L1.23104 8.53804L8.57335 1.196C8.68706 1.08216 8.83826 1.01953 8.99921 1.01953C9.16002 1.01953 9.31122 1.08216 9.42507 1.196L16.7657 8.53653C16.7668 8.53763 16.7681 8.53873 16.7692 8.53983C17.0028 8.77507 17.0024 9.15644 16.7681 9.39086Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HouseAlternate;
