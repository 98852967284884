import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const LowestPriceGuarantee = ({
  color = iconDefault.color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75293 0C1.64836 0 0.75293 0.895431 0.75293 2V9.91103C0.75293 10.7034 1.22071 11.421 1.94565 11.7409L6.6351 13.8097C7.14937 14.0366 7.73538 14.0366 8.24966 13.8097L12.9391 11.7409C13.664 11.421 14.1318 10.7034 14.1318 9.91103V2C14.1318 0.895431 13.2364 0 12.1318 0H2.75293ZM8.71798 5.03283L10.4457 5.1487C10.6766 5.16082 10.8772 5.30498 10.9523 5.51516C11.0288 5.72804 10.9638 5.95169 10.782 6.0972L9.42666 7.12923C9.39924 7.15079 9.38625 7.18582 9.39491 7.2195L9.80338 8.77833C9.84379 8.94674 9.80338 9.11516 9.69224 9.2445C9.58543 9.3698 9.42089 9.4439 9.2549 9.4439C9.14665 9.4439 9.03984 9.41291 8.94747 9.35498L7.4738 8.51022C7.44349 8.4927 7.40452 8.4927 7.37421 8.51022L5.91498 9.35767C5.82693 9.41291 5.72012 9.4439 5.61043 9.4439C5.4329 9.4439 5.26402 9.36441 5.15577 9.23237C5.05041 9.10168 5.01721 8.93462 5.06484 8.77294L5.47187 7.21815C5.48053 7.18582 5.46754 7.15079 5.44011 7.12923L4.10501 6.09989C3.92026 5.95304 3.85531 5.72938 3.93325 5.51651C4.00975 5.30633 4.20893 5.16352 4.44275 5.15139L6.16756 5.03552C6.20365 5.03283 6.23396 5.01127 6.24839 4.98028L6.90801 3.48343C7.00038 3.27594 7.20389 3.14795 7.44349 3.14795C7.68309 3.14795 7.88805 3.27594 7.97898 3.48208L8.63715 4.97759C8.65014 5.00992 8.68189 5.03013 8.71798 5.03283Z"
      fill={color}
    />
  </svg>
);

export default LowestPriceGuarantee;
