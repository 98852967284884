import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Eye = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="56"
    height="46"
    viewBox="0 0 56 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.4 6.99999C29.4 7.79999 28.8 8.39999 28 8.39999C27.2 8.39999 26.6 7.79999 26.6 6.99999V1.79999C26.6 0.999994 27.2 0.399994 28 0.399994C28.8 0.399994 29.4 0.999994 29.4 1.79999V6.99999ZM52.6 26.8C53.2 26.3 54.1 26.4 54.6 27C55.8 28.6 55.8 30.9 54.6 32.5C48.2 40.8 38.5 45.5 28 45.5C17.5 45.5 7.80005 40.8 1.40005 32.5C0.100049 30.9 0.100049 28.6 1.40005 27C7.80005 18.8 17.5 14 28 14C35.7001 14 43.3 16.7 49.3 21.8C49.7001 22 50 22.3 50.3 22.6C50.9 23.1 50.9 24 50.4 24.6C49.9 25.2 49 25.2 48.4 24.7C48.1 24.4 47.8 24.1 47.5 23.9C45.1 21.9 42.4001 20.3 39.5 19.1C42.1 21.9 43.7001 25.7 43.7001 29.8C43.7001 33.9 42.1 37.7 39.5 40.5C44.5 38.5 49 35.2 52.4 30.8C52.9 30.2 52.9 29.4 52.4 28.8C51.9 28.2 52 27.3 52.6 26.8ZM28 42.7C35.1 42.7 40.9 36.9 40.9 29.8C40.9 22.6 35.1 16.8 28 16.8C20.8 16.8 15.1 22.7 15.1 29.8C15.1 36.9 20.9 42.7 28 42.7ZM3.60005 30.8C3.10005 30.2 3.10005 29.4 3.60005 28.8C7.00005 24.3 11.5 21 16.5 19C13.9 21.8 12.3 25.6 12.3 29.7C12.3 33.8 13.9 37.6 16.5 40.4C11.5 38.5 7.00005 35.2 3.60005 30.8ZM28 35.8C24.7001 35.8 22 33.1 22 29.8C22 26.5 24.7001 23.8 28 23.8C31.3 23.8 34 26.5 34 29.8C34 33.1 31.3 35.8 28 35.8ZM31.1 29.8C31.1 31.5 29.7 33 27.9 33C26.2 33 24.7 31.6 24.7 29.8C24.7 28.1 26.1 26.6 27.9 26.6C29.7 26.6 31.1 28 31.1 29.8ZM45.2001 14C45.6001 14 45.9001 13.9 46.2001 13.6L48.8 11C49.3 10.5 49.3 9.59999 48.8 8.99999C48.3 8.49999 47.4001 8.49999 46.8 8.99999L44.2001 11.6C43.7001 12.1 43.7001 13 44.2001 13.6C44.4001 13.8 44.8 14 45.2001 14ZM10.8 14C10.4 14 10.1 13.9 9.80005 13.6L7.20005 11C6.70005 10.4 6.70005 9.49999 7.20005 8.99999C7.80005 8.49999 8.70005 8.49999 9.20005 8.99999L11.8 11.6C12.3 12.2 12.3 13.1 11.8 13.6C11.5 13.9 11.2 14 10.8 14Z"
      fill={color}
    />
  </svg>
);

export default Eye;
