import { Direction } from 'types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Arrow = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    height="24px"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    x="0px"
    y="0px"
    {...props}
  >
    <path
      transform={getRotation(Direction.BOTTOM, 24, 24)}
      fill={color}
      d="M2.2524,7.3359a1,1,0,0,0,.083,1.4112l9,8a1,1,0,0,0,1.3292,0l9-8a1,1,0,1,0-1.3292-1.4942L12,14.6621,3.6646,7.2529A.9992.9992,0,0,0,2.2524,7.3359Z"
    />
  </svg>
);

export default Arrow;
