import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const VerifiedAlternate = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_913_20010)">
      <path
        d="M10.7963 7.05142C10.613 6.59931 10.613 6.09832 10.7963 5.65843L10.8574 5.5118C11.2483 4.58313 10.7963 3.50784 9.86776 3.11683L9.73337 3.05573C9.28132 2.87244 8.92702 2.51809 8.74375 2.06597L8.69488 1.94378C8.2917 1.01512 7.22878 0.575229 6.28803 0.954025L6.16585 1.0029C5.71381 1.18619 5.21289 1.18619 4.76084 1.0029L4.65088 0.954025C3.73457 0.575229 2.65943 1.02734 2.26847 1.956L2.2196 2.05375C2.03633 2.50587 1.68202 2.86022 1.22998 3.04351L1.12002 3.09239C0.203704 3.4834 -0.248345 4.5587 0.142616 5.48736L0.191486 5.59733C0.374749 6.04944 0.374749 6.55043 0.191486 6.99032L0.142616 7.12473C-0.248345 8.05339 0.191486 9.12869 1.13224 9.50748L1.25441 9.55636C1.70646 9.73965 2.06077 10.094 2.24403 10.5461L2.30512 10.6805C2.68386 11.6214 3.759 12.0613 4.68753 11.6825L4.82193 11.6214C5.27398 11.4381 5.77489 11.4381 6.22694 11.6214L6.3369 11.6703C7.26543 12.0613 8.34057 11.6092 8.73153 10.6805L8.78041 10.5828C8.96367 10.1307 9.31798 9.77631 9.77002 9.59302L9.86776 9.55636C10.8085 9.16534 11.2483 8.10227 10.8574 7.16139L10.7963 7.05142ZM4.98076 8.70101L2.59834 6.69706L3.33139 5.82949L4.84636 7.11251L7.522 3.93552L8.38944 4.66867L4.98076 8.70101Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_913_20010">
        <rect
          width="11"
          height="11"
          fill="white"
          transform="translate(0 0.818176)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default VerifiedAlternate;
