import type { IconProps } from 'components/ui/Icon';

const Visa = ({ getRotation, className, ...props }: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="43"
    height="44"
    viewBox="0 0 43 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <rect x="8" y="3.5" width="27" height="37" fill="#FFCA58" />
      <path
        d="M21.4995 25.5833C22.5074 25.5833 23.9839 22.3904 24.1537 17.5209H18.8452C19.015 22.3904 20.4916 25.5833 21.4995 25.5833Z"
        fill="#155263"
      />
      <path
        d="M24.3682 8.18042C25.3296 10.0578 25.8698 12.854 25.9624 15.729H30.3672C30.0096 12.1981 27.621 9.29011 24.3682 8.18042Z"
        fill="#155263"
      />
      <path
        d="M12.6323 15.729H17.0371C17.1297 12.854 17.6699 10.0578 18.6313 8.18042C15.3784 9.29011 12.9898 12.1981 12.6323 15.729Z"
        fill="#155263"
      />
      <path
        d="M21.4995 7.66663C20.4916 7.66663 19.015 10.8595 18.8452 15.7291H24.1537C23.9839 10.8596 22.5074 7.66663 21.4995 7.66663Z"
        fill="#155263"
      />
      <path
        d="M34.0416 0.5H8.95836C6.98204 0.5 5.375 2.10704 5.375 4.08336V39.9167C5.375 41.893 6.98204 43.5 8.95836 43.5H34.0417C36.018 43.5 37.625 41.893 37.625 39.9166V4.08336C37.625 2.10704 36.018 0.5 34.0416 0.5ZM27.7709 38.125H15.2291C14.734 38.125 14.3333 37.7243 14.3333 37.2291C14.3333 36.734 14.734 36.3333 15.2291 36.3333H27.7708C28.2659 36.3333 28.6666 36.734 28.6666 37.2291C28.6666 37.7243 28.266 38.125 27.7709 38.125ZM31.3541 32.75H11.6459C11.1507 32.75 10.75 32.3493 10.75 31.8541C10.75 31.359 11.1507 30.9583 11.6459 30.9583H31.3542C31.8494 30.9583 32.2501 31.359 32.2501 31.8541C32.25 32.3493 31.8493 32.75 31.3541 32.75ZM21.5 27.375C15.5721 27.375 10.75 22.5529 10.75 16.625C10.75 10.6971 15.5721 5.875 21.5 5.875C27.4279 5.875 32.25 10.6971 32.25 16.625C32.25 22.5529 27.4279 27.375 21.5 27.375Z"
        fill="#155263"
      />
      <path
        d="M24.3682 25.0695C27.621 23.9597 30.0096 21.0518 30.3672 17.5209H25.9624C25.8698 20.3959 25.3296 23.1921 24.3682 25.0695Z"
        fill="#155263"
      />
      <path
        d="M12.6323 17.5209C12.9898 21.0518 15.3784 23.9598 18.6313 25.0695C17.6699 23.1921 17.1297 20.3959 17.0371 17.5209H12.6323Z"
        fill="#155263"
      />
    </g>
  </svg>
);

export default Visa;
