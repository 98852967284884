import { Direction } from 'types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const FiltersAlternate = ({
  color = iconDefault.color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    className={className}
    {...props}
    fill="none"
    transform={getRotation(Direction.RIGHT, 20, 20)}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1C0.447715 1 0 1.44772 0 2C0 2.55228 0.447715 3 1 3H19C19.5523 3 20 2.55228 20 2C20 1.44772 19.5523 1 19 1H1ZM1 9C0.447715 9 0 9.44771 0 10C0 10.5523 0.447715 11 1 11H19C19.5523 11 20 10.5523 20 10C20 9.44771 19.5523 9 19 9H1ZM0 18C0 17.4477 0.447715 17 1 17H19C19.5523 17 20 17.4477 20 18C20 18.5523 19.5523 19 19 19H1C0.447715 19 0 18.5523 0 18Z"
      fill={color}
    />
    <circle cx="16" cy="10" r="2" fill={color} />
    <circle cx="10" cy="18" r="2" fill={color} />
    <circle cx="4" cy="2" r="2" fill={color} />
  </svg>
);

export default FiltersAlternate;
