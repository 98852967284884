import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const SatisfactionGuarantee = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.461 22.9816L17.2788 24.9013L22.515 30.7375L22.5252 30.7272L22.5323 30.7375L31.9223 21.1947L30.2463 18.9917L22.6145 25.2723L19.461 22.9816Z"
      fill={color}
    />
    <path
      d="M10.7705 16.367C10.7705 16.367 11.6502 37.2618 23.9998 37.2618C36.3491 37.2618 37.2287 16.367 37.2287 16.367L23.9995 10.7371L10.7705 16.367Z"
      stroke={color}
      strokeWidth="3"
    />
    <circle cx="24" cy="24" r="22.5" stroke={color} strokeWidth="3" />
  </svg>
);

export default SatisfactionGuarantee;
