import type { IconProps } from 'components/ui/Icon';

const Maestro = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg
    {...props}
    width="29"
    height="18"
    viewBox="0 0 29 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7425 15.3536C13.2786 16.6169 11.3796 17.3796 9.30457 17.3796C4.6745 17.3796 0.921082 13.5826 0.921082 8.89878C0.921082 4.21496 4.6745 0.417969 9.30457 0.417969C11.3796 0.417969 13.2786 1.18061 14.7425 2.44392C16.2064 1.18061 18.1054 0.417969 20.1804 0.417969C24.8105 0.417969 28.5639 4.21496 28.5639 8.89878C28.5639 13.5826 24.8105 17.3796 20.1804 17.3796C18.1054 17.3796 16.2064 16.6169 14.7425 15.3536Z"
      fill="#EB001B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7425 15.3536C16.5451 13.7981 17.688 11.4835 17.688 8.89878C17.688 6.31409 16.5451 3.99946 14.7425 2.44392C16.2064 1.18061 18.1054 0.417969 20.1804 0.417969C24.8105 0.417969 28.5639 4.21496 28.5639 8.89878C28.5639 13.5826 24.8105 17.3796 20.1804 17.3796C18.1054 17.3796 16.2064 16.6169 14.7425 15.3536Z"
      fill="#00A2E5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7425 15.3535C16.5451 13.798 17.688 11.4833 17.688 8.89867C17.688 6.314 16.5451 3.99938 14.7425 2.44385C12.94 3.99938 11.797 6.314 11.797 8.89867C11.797 11.4833 12.94 13.798 14.7425 15.3535Z"
      fill="#7375CF"
    />
  </svg>
);

export default Maestro;
