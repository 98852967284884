import config from 'config';

/**
 * Given a stage, returns a proxy domain
 * This should be REMOVED once the old platform is killed
 * and moved to a config file instead
 *
 * @param stage - stage to return the proxy domain for
 * @returns the proxy domain
 */
export const getProxyDomainByStage = (stage: string): string => {
  const { proxy } = config.app.domains;

  return (
    {
      production: `https://${proxy.production}`,
      staging: `https://${proxy.staging}`,
      qa: `https://${proxy.staging}`,
    }[stage] || ''
  );
};

export const buildUrl = (baseUrl: string, ...paths: string[]): string => {
  if (!baseUrl) {
    return buildUrlPath(...paths);
  }

  return removeTrailingSlash(
    new URL(buildUrlPath(...paths), baseUrl).toString(),
  );
};

export const buildUrlPath = (...paths: string[]): string => {
  const path = `/${paths.join('/')}`;
  return removeTrailingSlash(path.replace(/[/]{2,}/g, '/'));
};

export const removeTrailingSlash = (path: string): string => {
  return path.replace(/\/$/, '');
};

export const getQueryParameterByName = (
  name: string,
  url = window.location.href,
): string | null => {
  const sanitizedName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${sanitizedName}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url);
  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
