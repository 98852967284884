import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Urology = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1565 34.3894C12.1868 36.4221 15.7994 36.6484 18.0547 34.3902C19.0947 33.3506 19.667 31.9699 19.6671 30.5009C19.6671 29.0317 19.095 27.6511 18.0561 26.6128C17.2543 25.8103 16.6 24.8893 16.111 23.8744C15.8683 23.3708 15.8688 22.784 16.1121 22.2806C16.3555 21.7773 16.8153 21.4126 17.3608 21.29C18.1881 21.1041 18.7887 20.3518 18.7887 19.5014C18.7887 18.6509 18.1882 17.8986 17.3608 17.7127C16.8153 17.5903 16.3555 17.2254 16.1121 16.7221C15.8688 16.2188 15.8683 15.632 16.111 15.1283C16.6 14.1134 17.2541 13.1924 18.0552 12.3906C19.0947 11.3516 19.667 9.97096 19.667 8.50184C19.667 7.03271 19.0949 5.65207 18.056 4.61378C15.9069 2.46203 12.3047 2.46252 10.1574 4.61256C6.18646 8.58336 4 13.8709 4 19.5014C4 25.1318 6.18646 30.4192 10.1565 34.3894Z"
      stroke={color}
      strokeWidth="3"
    />
    <path
      d="M48.693 4.61324C46.5034 2.46244 42.8319 2.46231 40.6431 4.61251C39.5832 5.65198 39 7.03269 39 8.50164C39 9.97071 39.5831 11.3513 40.642 12.3895C41.4592 13.192 42.126 14.113 42.6244 15.1279C42.8718 15.6314 42.8713 16.2182 42.6233 16.7216C42.3752 17.2249 41.9066 17.5896 41.3506 17.7122C40.5074 17.8981 39.8953 18.6503 39.8953 19.5007C39.8953 20.3511 40.5073 21.1034 41.3506 21.2893C41.9066 21.4117 42.3752 21.7766 42.6233 22.2799C42.8713 22.7832 42.8718 23.3699 42.6244 23.8736C42.126 24.8885 41.4594 25.8094 40.6428 26.6111C39.5834 27.6502 39.0001 29.0307 39.0001 30.4998C39.0001 31.9689 39.5832 33.3495 40.6421 34.3877C42.9734 36.678 46.6536 36.3917 48.6924 34.3889C52.7394 30.4181 54.9677 25.131 54.9677 19.5007C54.9677 16.3342 54.2629 13.2762 52.9262 10.5"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M30.5596 35.3423C30.6128 35.3489 30.6664 35.3526 30.72 35.3534C31.0901 35.3591 32.28 35.5068 33.3623 36.0477C33.3962 36.0646 33.4308 36.0803 33.4659 36.0946C36.9129 37.502 39.1124 40.4264 39.1124 43.6217C39.1124 46.7231 36.994 49.6857 33.4684 51.1178C32.9028 51.3475 32.5329 51.8971 32.5329 52.5075V55.3552C32.5329 55.4351 32.468 55.5 32.388 55.5H25.8083C25.7283 55.5 25.6635 55.4351 25.6635 55.3552V52.5075C25.6635 51.8971 25.2935 51.3475 24.7279 51.1178C21.2023 49.6858 19.084 46.7233 19.084 43.6217C19.084 40.4264 21.2834 37.502 24.7305 36.0946C24.7493 36.0869 24.768 36.0788 24.7866 36.0704C25.2462 35.8605 26.4833 35.4161 27.5454 35.3508C27.5759 35.3489 27.6063 35.3461 27.6366 35.3424C28.1146 35.2836 28.6024 35.2525 29.0982 35.2525C29.5941 35.2525 30.0818 35.2835 30.5596 35.3423Z"
      stroke={color}
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M25.8119 35.2045V23.2327C25.8119 21.0236 24.0211 19.2327 21.8119 19.2327H19.52"
      stroke={color}
      strokeWidth="3"
    />
    <path
      d="M33.0714 35.2045V23.2327C33.0714 21.0236 34.8622 19.2327 37.0714 19.2327H39.3633"
      stroke={color}
      strokeWidth="3"
    />
  </svg>
);

export default Urology;
