import { Direction } from 'types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Phone = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
    <path
      transform={getRotation(Direction.RIGHT, 11, 11)}
      d="M10.1173 7.22024C9.44388 7.22024 8.78262 7.11491 8.15599 6.90783C7.84894 6.8031 7.47147 6.89918 7.28407 7.09165L6.04723 8.02533C4.61285 7.25966 3.72931 6.37639 2.97409 4.95278L3.8803 3.74816C4.11574 3.51304 4.20018 3.16958 4.09901 2.84731C3.89104 2.21739 3.78539 1.55644 3.78539 0.882693C3.78542 0.395971 3.38945 0 2.90276 0H0.882662C0.39597 0 0 0.395971 0 0.882664C0 6.46144 4.53858 11 10.1173 11C10.604 11 11 10.604 11 10.1173V8.10287C11 7.61621 10.604 7.22024 10.1173 7.22024Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default Phone;
