import type { IconProps } from 'components/ui/Icon';

const LocationPin = ({
  color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    className={className}
    {...props}
    fill={color || 'currentColor'}
    width="14"
    height="16"
    viewBox="0 0 14 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.04311 0.125C6.96834 0.125 6.8903 0.125 6.81553 0.128246C3.60978 0.238625 0.960002 2.72538 0.654383 5.91012C0.59586 6.52694 0.625122 7.14701 0.738916 7.73786C0.738916 7.73786 0.74867 7.81253 0.784433 7.95212C0.881971 8.39039 1.02828 8.81567 1.2136 9.21173C1.87361 10.7733 3.32042 13.1789 6.46114 15.7825C6.62695 15.9188 6.83503 15.9935 7.04962 15.9935C7.2642 15.9935 7.47228 15.9188 7.63809 15.7825C10.7756 13.1821 12.2224 10.7733 12.8791 9.22472C13.0677 8.81892 13.214 8.39039 13.3115 7.95537C13.3311 7.87745 13.3441 7.81253 13.3538 7.75084C13.4318 7.35153 13.4709 6.94248 13.4709 6.53344C13.4644 3.00133 10.5837 0.125 7.04311 0.125ZM3.72032 6.60486C3.72032 4.77712 5.2094 3.28701 7.04311 3.28701C8.87683 3.28701 10.3659 4.78037 10.3659 6.6081C10.3659 8.43584 8.87683 9.92595 7.04311 9.92595C5.2094 9.92595 3.72032 8.43259 3.72032 6.60486ZM4.51688 6.6081C4.51688 7.99757 5.64832 9.13057 7.04311 9.13057C8.43791 9.13057 9.56935 7.99757 9.56935 6.6081C9.56935 5.21864 8.43465 4.08563 7.04311 4.08563C5.65157 4.08563 4.51688 5.21864 4.51688 6.6081ZM12.5312 7.77681C12.554 7.67618 12.5638 7.62099 12.5638 7.61449C12.632 7.25089 12.6678 6.89379 12.6678 6.53668C12.6678 3.37142 10.0343 0.813241 6.84479 0.92362C4.0357 1.02101 1.71755 3.19936 1.44769 5.98804C1.39567 6.52694 1.42168 7.07234 1.52247 7.60151C1.52247 7.60475 1.52328 7.608 1.5241 7.61125C1.52491 7.61449 1.52572 7.61774 1.52572 7.62099C1.52614 7.6231 1.52668 7.62581 1.52733 7.62912C1.53167 7.65128 1.54126 7.70014 1.55823 7.77357C1.64602 8.15989 1.77282 8.53323 1.93863 8.89034C2.56938 10.3804 3.95116 12.6692 6.96508 15.1689C6.99434 15.1916 7.02686 15.1981 7.04637 15.1981C7.06912 15.1981 7.09839 15.1916 7.12765 15.1689C10.1416 12.6724 11.5201 10.3837 12.1476 8.90332C12.3167 8.53323 12.4435 8.15989 12.5312 7.77681Z"
    />
  </svg>
);

export default LocationPin;
