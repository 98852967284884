import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Bell = ({
  color = iconDefault.color,
  secondaryColor = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="25"
    height="27"
    viewBox="0 0 25 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 23C8.01868 25.3531 10.3241 27 13 27C15.6759 27 17.9812 25.3531 19 23H7Z"
      fill={secondaryColor}
    />
    <path
      d="M12.4997 2.38851C13.517 2.38851 14.4938 2.568 15.4003 2.89662V2.78496C15.4003 1.24933 14.151 0 12.6154 0H12.3846C10.8489 0 9.59961 1.24933 9.59961 2.78496V2.8952C10.5056 2.56756 11.4821 2.38851 12.4997 2.38851Z"
      fill={color}
    />
    <path
      d="M20.93 20.2775H4.06989C4.36605 19.9595 4.73403 19.4637 5.08804 18.7157C6.20939 16.3463 6.41669 13.1178 6.41669 10.9387C6.41669 7.58543 9.14642 4.85577 12.4997 4.85577C15.8451 4.85577 18.5697 7.57228 18.5827 10.9145L18.5827 10.9308L18.5832 10.9436C18.5835 13.1226 18.7913 16.3481 19.9119 18.7157C20.2658 19.4635 20.6337 19.9594 20.93 20.2775Z"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);

export default Bell;
