import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const ServiceFeePriceTag = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.9373 4.875H26.6891C25.8127 4.875 24.5878 5.38272 23.9685 6.00148L5.80516 24.1647C4.56495 25.4036 4.56495 27.4346 5.80516 28.672L19.3293 42.1956C20.5667 43.4344 22.5958 43.4344 23.8351 42.1942L41.9984 24.0337C42.6171 23.4151 43.1249 22.1882 43.1249 21.3137V8.06263C43.1249 6.30983 41.69 4.875 39.9373 4.875ZM34.4245 14.7499C34.8995 14.2664 35.6696 14.2664 36.1446 14.7499C36.6196 15.2334 36.6196 16.0172 36.1446 16.5007L21.5493 31.3567C21.0743 31.8401 20.3042 31.8401 19.8292 31.3567L13.7478 25.1667C13.2728 24.6832 13.2728 23.8994 13.7478 23.4159C14.2228 22.9324 14.9929 22.9324 15.4679 23.4159L20.6892 28.7305L34.4245 14.7499ZM38.555 11.6602C39.7781 11.6602 40.7696 10.6687 40.7696 9.4456C40.7696 8.22248 39.7781 7.23095 38.555 7.23095C37.3319 7.23095 36.3404 8.22248 36.3404 9.4456C36.3404 10.6687 37.3319 11.6602 38.555 11.6602Z"
      fill={color}
    />
  </svg>
);

export default ServiceFeePriceTag;
