import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Airplane = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7002 1.30001C17.6002 1.10001 17.5002 0.800012 17.3002 0.700012C17.1002 0.500012 16.9002 0.400012 16.7002 0.300012C16.5002 0.200012 16.2002 0.200012 16.0002 0.200012C15.8002 0.200012 15.5002 0.300012 15.3002 0.400012C15.1002 0.500012 14.9002 0.700012 14.7002 0.800012L10.7002 4.80001L9.10019 4.50001L9.9002 3.70001C10.1002 3.50001 10.1002 3.10001 9.9002 2.90001C9.7002 2.70001 9.30019 2.70001 9.10019 2.90001L7.7002 4.20001L6.0002 3.80001L6.7002 3.10001C6.9002 2.90001 6.9002 2.50001 6.7002 2.30001C6.5002 2.10001 6.10019 2.10001 5.9002 2.30001L4.6002 3.50001L2.8002 3.10001C2.3002 3.00001 1.5002 3.10001 1.2002 3.40001L0.500195 4.10001C0.300195 4.30001 0.200195 4.60001 0.200195 4.80001C0.300195 5.40001 1.0002 5.70001 1.9002 6.10001C2.0002 6.10001 2.1002 6.20001 2.2002 6.20001L2.5002 6.30001C4.0002 7.10001 5.4002 7.70001 6.8002 8.50001C6.5002 8.90001 6.1002 9.20001 5.8002 9.60001C5.3002 10.1 4.9002 10.6 4.3002 11.1H2.8002C2.4002 11.1 2.1002 11.2 1.8002 11.5L1.5002 11.8C1.2002 12.1 1.0002 12.4 1.1002 12.8C1.2002 13.3 1.7002 13.5 1.9002 13.6L3.7002 14.3L4.4002 16.1C4.5002 16.3 4.7002 16.8 5.2002 16.9C5.6002 17 6.0002 16.7 6.2002 16.5L6.5002 16.2C6.8002 15.9 6.9002 15.4 6.9002 15L6.8002 13.8L7.3002 13.3C8.0002 12.6 8.7002 11.8 9.5002 11.2C10.2002 12.6 10.9002 14.1 11.6002 15.6L11.7002 15.9C11.7002 16 11.8002 16.1 11.8002 16.2C12.2002 17.1 12.5002 17.7 13.1002 17.8H13.3002C13.5002 17.8 13.7002 17.7 13.9002 17.5L14.6002 16.8C15.0002 16.4 15.1002 15.8 14.9002 15.2L14.5002 13.4L16.0002 11.9C16.2002 11.7 16.2002 11.3 16.0002 11.1C15.8002 10.9 15.4002 10.9 15.2002 11.1L14.2002 12.1L13.8002 10.4L15.0002 8.90001C15.2002 8.70001 15.2002 8.30001 15.0002 8.10001C14.8002 7.90001 14.4002 7.90001 14.2002 8.10001L13.5002 8.80001L13.2002 7.20001L17.2002 3.20001C17.4002 3.00001 17.5002 2.80001 17.6002 2.60001C17.7002 2.40001 17.8002 2.20001 17.8002 1.90001C17.8002 1.70001 17.8002 1.50001 17.7002 1.30001Z"
      fill={color}
    />
  </svg>
);

export default Airplane;
