import type { IconProps } from 'components/ui/Icon';

const House = ({
  color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="13"
    height="13"
    viewBox="0 0 13 13"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
  >
    <path
      fill={color || 'currentColor'}
      d="M12.1666 6.26076C12.167 6.3968 12.1314 6.53051 12.0634 6.64833C11.9954 6.76615 11.8975 6.86388 11.7795 6.93159C11.6615 6.9993 11.5277 7.03458 11.3917 7.03385C11.2556 7.03312 11.1222 6.9964 11.005 6.92743V11.6499C11.005 11.855 10.9236 12.0518 10.7788 12.1971C10.634 12.3423 10.4376 12.4243 10.2325 12.4249H8.58329C8.37788 12.4245 8.18101 12.3427 8.03577 12.1974C7.89052 12.0522 7.80873 11.8553 7.80829 11.6499V8.04326H5.19579V11.6491C5.19557 11.8543 5.11407 12.051 4.96914 12.1963C4.82421 12.3415 4.62764 12.4234 4.42245 12.4241H2.76995C2.56461 12.4234 2.36787 12.3416 2.22267 12.1964C2.07748 12.0512 1.99561 11.8544 1.99495 11.6491V6.92826C1.83252 7.0233 1.64102 7.05584 1.45631 7.0198C1.27161 6.98376 1.10638 6.8816 0.991598 6.73248C0.876813 6.58335 0.820345 6.39748 0.832775 6.2097C0.845205 6.02193 0.92568 5.84513 1.05912 5.71243L5.81912 0.954096L5.96995 0.80243C6.03842 0.730836 6.12068 0.673855 6.21177 0.634926C6.30286 0.595998 6.40089 0.575928 6.49995 0.575928C6.59901 0.575928 6.69705 0.595998 6.78814 0.634926C6.87923 0.673855 6.96149 0.730836 7.02995 0.80243L7.17995 0.95243L11.9416 5.71243C12.0136 5.78432 12.0706 5.86983 12.1093 5.96397C12.1479 6.05812 12.1674 6.15901 12.1666 6.26076Z"
    />
  </svg>
);

export default House;
