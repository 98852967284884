import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { getContentfulLocale } from 'utils/locale';
import { useTranslation } from 'hooks/useTranslation';
import Link from './Link';
import type { ScriptWithKey } from 'types/Utilities';

let isScriptLoaded = false;

type TrustBoxImplProps = {
  height: number;
  mobileHeight?: number;
  rating?: number | string;
  reviewLanguages?: string | string[];
  templateId?: string;
  theme?: 'dark' | 'light';
  tags?: string | null;
  className?: string;
};

// Based on: https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application
const TrustBoxImpl = ({
  height,
  mobileHeight = 300,
  rating = '4,5',
  reviewLanguages,
  templateId = '53aa8912dec7e10d38f59f36',
  theme = 'light',
  tags = '',
  className = 'mb-10',
}: TrustBoxImplProps): JSX.Element => {
  const ref = useRef(null);
  const { locale } = useTranslation();
  const contentfulLocale = getContentfulLocale(locale);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 540px)').matches);
  }, []);

  useEffect(() => {
    // -- If the script is not loaded yet (by an existing instance of this component)
    // -- we load it (this means we are the first)
    // -- After the script finishes loading, it will populate the div.trustpilot-widget
    // -- with data, so we just need to wait.
    if (!isScriptLoaded) {
      const script = document.createElement('script') as ScriptWithKey;
      script.defer = true;
      script.key = 'trustPilot';
      script.src =
        'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';

      document.head.appendChild(script);
      isScriptLoaded = true;
      return;
    }

    // -- Now if we are not the first component instance to be loaded
    // -- we already have the script loaded (or still loading)
    // -- so we need to forcefully tell the Trustpilot object to
    // -- load content into our div (if the Trustpilot client exists)
    // -- Again, if not yet loaded, it will populate our divs once it is
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const tp = window.Trustpilot;
    if (tp && typeof tp.loadFromElement === 'function') {
      tp.loadFromElement(ref.current, true);
    }
  }, []);

  const additionalProps = tags ? { 'data-tags': tags } : {};

  return (
    <div
      ref={ref}
      className={clsx('trustpilot-widget', className)}
      data-locale={contentfulLocale}
      data-template-id={templateId}
      data-businessunit-id="5cd35d84c4dd7a0001be3252"
      data-style-height={`${isMobile ? mobileHeight : height}px`}
      data-style-width="100%"
      data-review-languages={reviewLanguages || locale}
      data-theme={theme}
      data-stars={rating}
      {...additionalProps}
    >
      <Link href="https://de.trustpilot.com/review/www.qunomedical.com">
        <a target="_blank" rel="noopener noreferrer">
          {' '}
          Trustpilot
        </a>
      </Link>
    </div>
  );
};

type Props = Omit<TrustBoxImplProps, 'height'> & {
  overflow?: boolean;
  lazyLoadOffset?: number;
  height?: number;
};

const TrustBox = ({
  overflow = false,
  lazyLoadOffset = 100,
  height = 150,
  ...props
}: Props): JSX.Element => {
  return (
    <LazyLoad
      classNamePrefix="trust-box"
      overflow={overflow}
      offset={lazyLoadOffset}
      height={height}
    >
      <TrustBoxImpl height={height} {...props} />
    </LazyLoad>
  );
};

export default TrustBox;
