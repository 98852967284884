import clsx from 'clsx';
import Link from 'components/ui/Link';
import config from 'config';
import { useTranslation } from 'hooks/useTranslation';
import styles from './Navigation.module.scss';

type Props = {
  onLinkClick: () => void;
  className?: string;
};

const Navigation = ({ onLinkClick, className }: Props): JSX.Element => {
  const { t, locale } = useTranslation();

  return (
    <nav
      className={clsx(
        'font-netto text-primary text-2xl lg:text-base uppercase text-center',
        styles.mainNavigation,
        className,
      )}
      data-cy="mainNavigation"
    >
      <ul className="flex flex-col pl-5 pr-4 lg:px-0 lg:flex-row lg:items-end">
        <li className="py-2 lg:py-0 lg:pr-6">
          <Link href={`/${locale}/${t('header.howItWorks.link')}`}>
            <a
              className={clsx(
                'relative py-2 no-underline text-primary',
                styles.animate,
              )}
              title={t('header.howItWorks')}
              onClick={onLinkClick}
            >
              {t('header.howItWorks')}
            </a>
          </Link>
        </li>
        <li className="py-2 lg:py-0 lg:pr-6">
          <Link href={`/${locale}/${t('header.about.link')}`}>
            <a
              className={clsx(
                'relative py-2 no-underline text-primary',
                styles.animate,
              )}
              title={t('header.about')}
              onClick={onLinkClick}
            >
              {t('header.about')}
            </a>
          </Link>
        </li>
        {config.homepage.headerReviewsLink.includes(locale) && (
          <li className="py-2 lg:py-0 lg:pr-6">
            <Link href={`/${locale}/${t(`header.reviews.link`)}`}>
              <a
                className={clsx(
                  'relative py-2 no-underline text-primary',
                  styles.animate,
                )}
                title={t(`header.reviews`)}
                onClick={onLinkClick}
              >
                {t(`header.reviews`)}
              </a>
            </Link>
          </li>
        )}
        <li className="py-2 lg:py-0 lg:pr-6">
          <Link href={`/${locale}/blog`}>
            <a
              className={clsx(
                'relative py-2 no-underline text-primary',
                styles.animate,
              )}
              title={t('blog')}
              onClick={onLinkClick}
            >
              {t('blog')}
            </a>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
