import type { IconProps } from 'components/ui/Icon';

const Lock = ({
  color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    className={className}
    {...props}
    width="10"
    height="13"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.39254 5.0643H8.06936L8.06808 3.42303C8.06671 1.635 6.63928 0.180451 4.88463 0.181641C3.13007 0.182943 1.70258 1.63762 1.70374 3.42565L1.70639 5.06701H1.59121C0.988024 5.06831 0.498624 5.56701 0.500003 6.18166V11.0683C0.500003 11.6829 0.990664 12.1816 1.59385 12.1816L8.39511 12.1776C8.99829 12.1776 9.48769 11.679 9.48631 11.0643V6.17648C9.48631 5.56182 8.99565 5.06442 8.39247 5.06442L8.39254 5.0643ZM2.87892 5.06699L2.87627 3.42563C2.87627 2.29627 3.77644 1.37766 4.88473 1.37637C5.99302 1.37637 6.89448 2.29507 6.89448 3.42304L6.89712 5.0644L2.87892 5.06699Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default Lock;
