import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const ChatBubbleMessage = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h18v18H0z" />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 .5C4.005.5.5 3.658.5 7.554c0 2.487 1.11 4.669 3.603 5.925V17.5l4.327-2.915c.19.01.378.023.57.023 4.995 0 8.5-3.158 8.5-7.054C17.5 3.658 13.995.5 9 .5z"
        />
        <path
          d="M14.1 7.5a1 1 0 1 1-2.001-.001A1 1 0 0 1 14.1 7.5zm-4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default ChatBubbleMessage;
