import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const User = ({
  color = iconDefault.color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <circle cx="8" cy="5" r="4.5" stroke={color} />
      <path
        d="M16.5 18C16.5 22.114 12.9473 25.5 8.5 25.5C4.05273 25.5 0.5 22.114 0.5 18C0.5 13.886 4.05273 10.5 8.5 10.5C12.9473 10.5 16.5 13.886 16.5 18Z"
        stroke={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="17" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default User;
