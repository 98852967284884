import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Envelope = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill={color}
  >
    <path
      d="M14.0057 11.7046C13.4086 12.1026 12.7151 12.313 12 12.313C11.285 12.313 10.5914 12.1026 9.99431 11.7046L0.159797 5.14801C0.105234 5.11164 0.0520781 5.07372 0 5.03472V15.7783C0 17.0101 0.999609 17.9877 2.20936 17.9877H21.7906C23.0224 17.9877 24 16.9881 24 15.7783V5.03467C23.9478 5.07376 23.8945 5.11178 23.8398 5.1482L14.0057 11.7046Z"
      fill={color}
    />
    <path
      d="M0.939844 3.97795L10.7744 10.5345C11.1466 10.7827 11.5733 10.9068 12 10.9068C12.4267 10.9068 12.8534 10.7827 13.2256 10.5345L23.0602 3.97795C23.6487 3.58584 24 2.92959 24 2.2213C24 1.00345 23.0092 0.0126953 21.7914 0.0126953H2.20861C0.990797 0.0127422 0 1.00349 0 2.22248C0 2.92959 0.351375 3.58584 0.939844 3.97795Z"
      fill={color}
    />
  </svg>
);

export default Envelope;
