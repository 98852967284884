import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const QuestionMark = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="34"
    height="58"
    viewBox="0 0 34 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.4593 26.3185L25.4661 26.313L25.4729 26.3073C28.6185 23.6573 32 20.3432 32 13.9976C32 9.94042 30.4218 6.41584 27.6264 3.91505C24.8386 1.42111 20.9043 0 16.2657 0C12.2421 0 8.86518 0.882419 5.14447 2.52936C3.6479 3.16847 3 4.48423 3 5.81675C3 6.73236 3.31149 7.58869 3.93402 8.22002C4.55929 8.85415 5.42509 9.18689 6.39143 9.18689C6.84447 9.18689 7.53245 9.07911 8.28922 8.70653C10.6928 7.59056 12.8521 6.81675 15.8029 6.81675C18.7187 6.81675 20.9623 7.59316 22.4569 8.82483C23.9342 10.0422 24.7543 11.7619 24.7543 13.8447C24.7543 17.3072 22.4301 19.5621 19.8787 21.631C15.0866 25.51 12.5657 29.748 12.5657 35.2524V38.7694C12.5657 40.7828 14.179 42.3689 16.1886 42.3689C18.1981 42.3689 19.8114 40.7828 19.8114 38.7694V35.176C19.8114 31.6851 21.6405 29.422 25.4593 26.3185ZM16.0343 47.6323C13.498 47.6323 11.2543 49.7406 11.2543 52.3786C11.2543 55.0167 13.498 57.125 16.0343 57.125C18.6742 57.125 20.8143 54.914 20.8143 52.3786C20.8143 49.7536 18.661 47.6323 16.0343 47.6323Z"
      fill={color}
    />
  </svg>
);

export default QuestionMark;
