import type { IconProps } from 'components/ui/Icon';

const AmericanExpress = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg
    {...props}
    width="43"
    height="29"
    viewBox="0 0 43 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.9047 0.614258H4.09521C2.17273 0.614258 0.614258 2.19565 0.614258 4.1464V25.3392C0.614258 27.29 2.17273 28.8714 4.09521 28.8714H38.9047C40.8272 28.8714 42.3857 27.29 42.3857 25.3392V4.1464C42.3857 2.19565 40.8272 0.614258 38.9047 0.614258Z"
      fill="#016FD0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5703 24.2688V14.1997L42.2319 14.2158V16.9972L40.1905 19.2107L42.2319 21.4446V24.285H38.9726L37.2405 22.3456L35.5207 24.2923L24.5703 24.2688Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7499 23.1649V15.3079H32.3155V17.1179H27.8757V18.3465H32.2096V20.1264H27.8757V21.3332H32.3155V23.1649H25.7499Z"
      fill="#016FD0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2827 23.1648L35.9156 19.2317L32.2825 15.3079H35.0945L37.3145 17.7981L39.5408 15.3079H42.2318V15.3697L38.6763 19.2317L42.2318 23.0533V23.1648H39.5137L37.2543 20.6496L35.0182 23.1648H32.2827Z"
      fill="#016FD0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.394 5.26245H29.6513L31.1466 8.70779V5.26245H36.4026L37.309 7.84369L38.2184 5.26245H42.2318V15.3315H21.0215L25.394 5.26245Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2001 6.3562L22.7643 14.2067H25.1208L25.769 12.6346H29.2811L29.9289 14.2067H32.3439L28.9225 6.3562H26.2001ZM26.4953 10.8728L27.5257 8.3737L28.5553 10.8728H26.4953Z"
      fill="#016FD0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.3118 14.2056V6.35522L35.6241 6.36678L37.3285 11.193L39.044 6.35522H42.2318V14.2056L40.1805 14.224V8.83155L38.2441 14.2056H36.3717L34.3951 8.81313V14.2056H32.3118Z"
      fill="#016FD0"
    />
  </svg>
);

export default AmericanExpress;
