import { Direction } from 'types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const PhoneLines = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    transform={getRotation(Direction.RIGHT, 512, 512)}
    fill={color || 'currentColor'}
  >
    <g>
      <g>
        <g>
          <path d="M511.95,412.553c-0.714-19.805-8.964-38.287-23.229-52.042c-27.931-26.933-51.333-42.481-73.647-48.929    c-30.745-8.886-59.225-0.806-84.641,24.016c-0.038,0.037-0.076,0.075-0.114,0.113l-27.04,26.838    c-16.899-9.526-49.759-30.442-85.261-65.944l-2.632-2.63c-35.508-35.507-56.423-68.373-65.94-85.253l26.84-27.041    c0.038-0.038,0.076-0.076,0.114-0.115c24.821-25.414,32.901-53.892,24.016-84.641c-6.449-22.313-21.996-45.715-48.93-73.646    C137.732,9.016,119.251,0.766,99.446,0.052C79.629-0.663,60.613,6.235,45.865,19.473l-0.574,0.516    c-0.266,0.239-0.526,0.486-0.78,0.739C15.152,50.087-0.238,91.188,0.003,139.589c0.411,82.222,45.6,176.25,120.879,251.528    c14.195,14.195,30.328,28.243,47.95,41.755c8.765,6.72,21.319,5.064,28.04-3.702c6.722-8.766,5.064-21.32-3.702-28.041    c-16.235-12.449-31.04-25.333-44.004-38.294C81.17,294.839,40.361,211.309,40.002,139.39    c-0.185-37.276,11.028-68.389,32.431-90.015l0.153-0.138c14.537-13.049,36.546-12.255,50.108,1.808    c51.779,53.698,48.03,79.049,25.15,102.511l-37.074,37.352c-5.814,5.858-7.433,14.686-4.076,22.226    c0.941,2.114,23.71,52.427,80.415,109.132l2.633,2.629c56.698,56.699,107.012,79.467,109.126,80.408    c7.538,3.359,16.368,1.739,22.225-4.075l37.353-37.075c23.465-22.881,48.816-26.628,102.511,25.151    c14.063,13.56,14.857,35.57,1.811,50.105l-0.142,0.157c-21.449,21.229-52.231,32.433-89.101,32.433c-0.304,0-0.608,0-0.913-0.002    c-29.471-0.147-63.598-8.226-98.689-23.362c-10.14-4.376-21.911,0.3-26.285,10.443c-4.375,10.143,0.3,21.911,10.443,26.286    c40.562,17.496,79.028,26.456,114.332,26.633c0.375,0.001,0.747,0.002,1.122,0.002c47.914,0,88.607-15.379,117.739-44.51    c0.253-0.254,0.499-0.513,0.738-0.78l0.519-0.577C505.768,451.385,512.665,432.357,511.95,412.553z" />
        </g>
      </g>
    </g>
  </svg>
);

export default PhoneLines;
