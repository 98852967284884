import type { IconProps } from 'components/ui/Icon';

const TR = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 1200 800" xmlns="http://www.w3.org/2000/svg">
    <rect width="1200" height="800" fill="#E30A17" />
    <circle cx="425" cy="400" r="200" fill="#fff" />
    <circle cx="475" cy="400" r="160" fill="#E30A17" />
    <polygon
      points="583.334,400 764.235,458.779 652.431,304.894 652.431,495.106 764.235,341.221"
      fill="#fff"
    />
  </svg>
);

export default TR;
