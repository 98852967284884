import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Shield = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="24"
    height="29"
    viewBox="0 0 24 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.500089 4.40352H0.500114L0.500065 4.39857C0.497533 4.14231 0.56938 3.89082 0.706899 3.67456C0.843723 3.45941 1.03971 3.28833 1.27132 3.18182C4.65244 1.70791 8.30679 0.964832 11.9951 1.00128L11.9951 1.00138L12.0049 1.00128C15.6932 0.964832 19.3476 1.70791 22.7287 3.18182C22.9603 3.28833 23.1563 3.45941 23.2931 3.67456C23.4306 3.89082 23.5025 4.14231 23.4999 4.39858L23.4999 4.39858V4.40352V13.78C23.4999 13.7801 23.4999 13.7802 23.4999 13.7802C23.4988 16.641 22.6314 19.4343 21.0119 21.7926C19.3926 24.1506 17.0973 25.963 14.4281 26.9914C14.4278 26.9915 14.4275 26.9916 14.4272 26.9918L13.1494 27.4801L13.1494 27.4801L13.1456 27.4816C12.4091 27.77 11.5909 27.77 10.8544 27.4815L10.8506 27.4801L9.57282 26.9918C9.57254 26.9916 9.57227 26.9915 9.572 26.9914C6.90273 25.9631 4.60739 24.1506 2.98807 21.7926C1.36863 19.4343 0.50124 16.641 0.500089 13.7802C0.500089 13.7802 0.500089 13.7801 0.500089 13.78L0.500089 4.40352Z"
      fill="none"
      fillOpacity="0.4"
      stroke={color}
    />
    <path
      d="M7.64559 12.2445L5.70312 13.9754L10.3639 19.2375L10.3731 19.2283L10.3793 19.2375L18.7376 10.6333L17.2458 8.64697L10.4525 14.3099L7.64559 12.2445Z"
      fill={color}
    />
  </svg>
);

export default Shield;
