import type { IconProps } from 'components/ui/Icon';

const IT = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd" strokeWidth="1pt">
      <path d="M0 0h639.995v480.004H0z" fill="#fff" />
      <path d="M0 0h213.334v480.004H0z" fill="#00a145" />
      <path d="M426.668 0h213.334v480.004H426.668z" fill="#e90035" />
    </g>
  </svg>
);

export default IT;
