import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Xmark = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
    <path
      d="M1232.46992,47.4990023 L1241.3826,38.5863263 C1242.20302,37.7659038 1242.20302,36.4357394 1241.3826,35.6154343 C1240.56218,34.7950117 1239.23213,34.7950117 1238.41171,35.6154343 L1229.49891,44.5282277 L1220.58612,35.6153169 C1219.7657,34.7948944 1218.43565,34.7948944 1217.61523,35.6153169 C1216.79492,36.4357394 1216.79492,37.7659038 1217.61523,38.5862089 L1226.52802,47.498885 L1217.61523,56.4116784 C1216.79492,57.2321009 1216.79492,58.5622653 1217.61523,59.3825704 C1218.43565,60.202993 1219.7657,60.202993 1220.58612,59.3825704 L1229.49891,50.469777 L1238.41171,59.3825704 C1239.23201,60.202993 1240.56218,60.202993 1241.3826,59.3825704 C1242.20302,58.5621479 1242.20302,57.2321009 1241.3826,56.4116784 L1232.46992,47.4990023 L1232.46992,47.4990023 Z"
      transform="translate(-1217 -35)"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export default Xmark;
