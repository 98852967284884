import type { IconProps } from 'components/ui/Icon';

const Paypal = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg
    {...props}
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.48268 15.6651L4.76183 13.8847L4.13998 13.8702H1.17053L3.23419 0.732293C3.24062 0.692509 3.26141 0.655579 3.29176 0.629307C3.32226 0.603035 3.36114 0.588623 3.40181 0.588623H8.40875C10.0711 0.588623 11.2182 0.935862 11.817 1.62133C12.0978 1.9429 12.2767 2.27903 12.3632 2.64879C12.454 3.03686 12.4555 3.50044 12.367 4.06597L12.3605 4.1071V4.4695L12.6413 4.62923C12.8777 4.75519 13.0657 4.89931 13.2098 5.0643C13.4499 5.33932 13.6053 5.68882 13.6709 6.10301C13.7388 6.52906 13.7164 7.03619 13.6053 7.61026C13.4771 8.27051 13.2701 8.84564 12.9903 9.31613C12.7331 9.74969 12.4054 10.1094 12.0162 10.388C11.6446 10.6528 11.2033 10.8539 10.7042 10.9825C10.2205 11.1089 9.66905 11.1727 9.06424 11.1727H8.67459C8.39604 11.1727 8.12541 11.2735 7.91294 11.4541C7.69988 11.6384 7.55903 11.8903 7.51582 12.1658L7.48637 12.3261L6.9931 15.4642L6.97082 15.5793C6.96484 15.6158 6.95468 15.634 6.93972 15.6463C6.92642 15.6576 6.90728 15.6651 6.88859 15.6651H4.48268Z"
      fill="#28356A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9074 4.14893C12.8926 4.24486 12.8754 4.34289 12.8563 4.44362C12.196 7.84755 9.93694 9.02348 7.05182 9.02348H5.5828C5.22993 9.02348 4.93254 9.28064 4.87766 9.63013L3.91252 15.777C3.87678 16.0065 4.05292 16.2133 4.28347 16.2133H6.88899C7.19745 16.2133 7.45956 15.9882 7.50815 15.6827L7.53372 15.5499L8.02429 12.4241L8.05584 12.2527C8.10384 11.9461 8.36654 11.7209 8.675 11.7209H9.06465C11.589 11.7209 13.5652 10.692 14.1428 7.71409C14.3839 6.47016 14.2591 5.43144 13.6206 4.70093C13.4275 4.4807 13.1878 4.29785 12.9074 4.14893Z"
      fill="#298FC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2164 3.87247C12.1155 3.84289 12.0114 3.81617 11.9047 3.792C11.7973 3.76843 11.6874 3.74757 11.5742 3.72925C11.1781 3.665 10.7441 3.63452 10.2792 3.63452H6.35481C6.25807 3.63452 6.16626 3.65644 6.08418 3.69607C5.90311 3.78345 5.76869 3.95549 5.7361 4.16611L4.90118 9.47543L4.87726 9.63021C4.93213 9.28072 5.22953 9.02355 5.58239 9.02355H7.05141C9.93653 9.02355 12.1956 7.84702 12.8559 4.44369C12.8756 4.34296 12.8922 4.24493 12.907 4.149C12.74 4.05998 12.5591 3.98386 12.3643 3.91901C12.3161 3.90294 12.2665 3.88748 12.2164 3.87247Z"
      fill="#22284F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.73633 4.16613C5.76892 3.9555 5.90334 3.78346 6.08441 3.69669C6.16709 3.6569 6.2583 3.63498 6.35504 3.63498H10.2795C10.7443 3.63498 11.1784 3.66561 11.5745 3.72986C11.6876 3.74803 11.7975 3.76905 11.9049 3.79261C12.0116 3.81664 12.1157 3.84351 12.2166 3.87293C12.2667 3.88794 12.3164 3.90356 12.365 3.91902C12.5598 3.98387 12.7408 4.06059 12.9079 4.14901C13.1043 2.89112 12.9062 2.03465 12.2289 1.25911C11.482 0.405199 10.1343 0.0397949 8.40958 0.0397949H3.40249C3.05022 0.0397949 2.74969 0.296959 2.69526 0.64705L0.609768 13.92C0.568651 14.1826 0.770352 14.4195 1.0341 14.4195H4.12526L5.73633 4.16613Z"
      fill="#28356A"
    />
  </svg>
);

export default Paypal;
