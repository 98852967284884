import type { IconProps } from 'components/ui/Icon';

const Thumb = ({
  className,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M3.85414 16.1875C1.72975 16.1875 0 17.9173 0 20.0416V32.375C0 34.4994 1.72975 36.2291 3.85414 36.2291H8.47914C9.34713 36.2291 10.1457 35.9362 10.7916 35.449V16.1875H3.85414Z"
        fill="#155263"
      />
      <path
        d="M37.0001 21.9689C37.0001 21.0424 36.6332 20.179 35.9965 19.5423C36.7165 18.7545 37.0834 17.6984 36.9832 16.5993C36.8028 14.6398 35.0375 13.1043 32.9625 13.1043H23.4396C23.9114 11.672 24.6668 9.04664 24.6668 6.93764C24.6668 3.59376 21.8255 0.770996 20.0418 0.770996C18.44 0.770996 17.296 1.67287 17.2468 1.70987C17.0649 1.85635 16.9585 2.07835 16.9585 2.31264V7.54041L12.5185 17.1589L12.3335 17.2529V33.7765C13.5884 34.3685 15.1763 34.6876 16.1876 34.6876H30.3386C32.0175 34.6876 33.4867 33.556 33.832 31.9944C34.0093 31.1911 33.906 30.3818 33.553 29.6788C34.6923 29.1053 35.4585 27.932 35.4585 26.5939C35.4585 26.0481 35.3336 25.5255 35.0962 25.0522C36.2355 24.4787 37.0001 23.3055 37.0001 21.9689Z"
        fill="#FFCA58"
      />
    </g>
  </svg>
);

export default Thumb;
