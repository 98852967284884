import type { IconProps } from 'components/ui/Icon';

const Visa = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg
    {...props}
    width="31"
    height="11"
    viewBox="0 0 31 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.05388 9.97443H5.44891L3.49551 2.52207C3.40279 2.17926 3.20593 1.8762 2.91634 1.73336C2.19366 1.37441 1.39731 1.08874 0.528564 0.944656V0.657741H4.72496C5.30412 0.657741 5.7385 1.08874 5.81089 1.58929L6.82443 6.96492L9.42812 0.657741H11.9607L8.05388 9.97443ZM13.4086 9.97443H10.9484L12.9742 0.657741H15.4344L13.4086 9.97443ZM18.6173 3.23875C18.6897 2.73695 19.124 2.45004 19.6308 2.45004C20.4272 2.378 21.2946 2.52208 22.0186 2.87979L22.453 0.873866C21.729 0.58695 20.9327 0.442871 20.21 0.442871C17.8222 0.442871 16.0847 1.73337 16.0847 3.52442C16.0847 4.88696 17.3154 5.60239 18.1842 6.03338C19.124 6.46313 19.486 6.75005 19.4136 7.1798C19.4136 7.82443 18.6897 8.11135 17.967 8.11135C17.0982 8.11135 16.2295 7.89647 15.4344 7.53752L15 9.54468C15.8688 9.9024 16.8087 10.0465 17.6774 10.0465C20.3548 10.1173 22.0186 8.82801 22.0186 6.89289C22.0186 4.45597 18.6173 4.31313 18.6173 3.23875ZM30.6286 9.97443L28.6752 0.657741H26.577C26.1426 0.657741 25.7082 0.944656 25.5634 1.37441L21.9462 9.97443H24.4788L24.9843 8.61313H28.096L28.3856 9.97443H30.6286ZM26.9389 3.16671L27.6616 6.67801H25.6358L26.9389 3.16671Z"
      fill="#172B85"
    />
  </svg>
);

export default Visa;
