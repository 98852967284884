import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Email = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="1 -57 511.999 511"
    fill={color}
  >
    <g>
      <path
        d="m432 .5h-352c-44.113281 0-80 35.886719-80 80v236c0 44.113281 35.886719 80 80 80h352c24.539062 0 47.378906-11.027344 62.660156-30.261719 5.515625-6.945312 6.625-18.84375-2.171875-27.21875l-.996093-.941406c-.800782-.75-1.972657-1.847656-3.46875-3.242187-2.953126-2.753907-7.175782-6.6875-12.25-11.398438-10.125-9.410156-23.628907-21.949219-37.140626-34.484375-27.011718-25.066406-54.035156-50.121094-54.035156-50.121094-8.101562-7.507812-20.753906-7.03125-28.265625 1.070313-7.507812 8.101562-7.03125 20.753906 1.070313 28.265625 29.285156 27.148437 69.199218 64.171875 91.289062 84.707031-5.15625 2.363281-10.828125 3.625-16.691406 3.625h-352c-5.9375 0-11.574219-1.3125-16.648438-3.644531l91.253907-84.695313c8.097656-7.515625 8.566406-20.167968 1.054687-28.265625-7.515625-8.097656-20.171875-8.566406-28.265625-1.054687l-87.246093 80.980468c-.089844-1.097656-.148438-2.203124-.148438-3.320312v-236c0-1.175781.0625-2.335938.160156-3.484375l151.234375 139.199219c3.691407 3.398437 8.523438 5.285156 13.542969 5.285156h102.132812c5.019532 0 9.851563-1.886719 13.542969-5.28125l151.226563-139.167969c.097656 1.140625.160156 2.285157.160156 3.449219v156c0 11.046875 8.953125 20 20 20s20-8.953125 20-20v-156c0-44.113281-35.886719-80-80-80zm-132.730469 181h-86.527343l-149.285157-137.40625c5.046875-2.300781 10.644531-3.59375 16.542969-3.59375h352c5.910156 0 11.515625 1.296875 16.570312 3.609375zm0 0"
        data-original="#000000"
        className="active-path"
        data-old_color="#000000"
      />
    </g>
  </svg>
);

export default Email;
