import config from 'config';
import { buildUrl, getProxyDomainByStage } from 'utils/url';
import type { AlternateSlug } from 'types/AlternateSlugs';

/**
 * Generates a list of alternate hreflang tags given an array of slugs
 * The format of the objects in the array is the following:
 *
 * {
 *    locale: LOCALE,
 *    slug: SLUG,
 * }
 *
 * The locale becomes the `hrefLang` and slug becomes the `href` params.
 *
 * @param {array} alternateSlugs - Array of objects that represents alternative slugs for that page
 * @param {string} slugPrefix - prefix to apply to any slug passed as an argument
 *
 * @returns {array} - Array of `link` JSX tags containing alternate hreflang information
 */
const generateHrefLangTags = (
  alternateSlugs: AlternateSlug[],
  slugPrefix = '',
): JSX.Element[] => {
  const mainSlug = Array.isArray(alternateSlugs)
    ? alternateSlugs.find((entry) => entry.locale === config.defaultLocale) ||
      alternateSlugs[0]
    : null;

  const nodes = [];
  const domain = getProxyDomainByStage(process.env.STAGE as string);

  if (Array.isArray(alternateSlugs)) {
    const alternates = alternateSlugs.map((entry: AlternateSlug, idx) => {
      if (entry.locale !== null && entry.slug !== null)
        return (
          <link
            key={`${entry.slug}_${idx}`}
            rel="alternate"
            hrefLang={entry.locale}
            /* eslint-disable-next-line react/forbid-dom-props */
            href={buildUrl(domain, entry.locale, slugPrefix, entry.slug)}
          />
        );
    });

    nodes.push(...alternates);
  }

  if (mainSlug) {
    nodes.push(
      <link
        key={mainSlug.slug}
        rel="alternate"
        hrefLang="x-default"
        /* eslint-disable-next-line react/forbid-dom-props */
        href={buildUrl(domain, mainSlug.locale, slugPrefix, mainSlug.slug)}
      />,
    );
  }

  // eslint-disable-next-line
  // @ts-ignore
  return nodes;
};

export default generateHrefLangTags;
