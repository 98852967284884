import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Gender = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="51"
    height="52"
    viewBox="0 0 51 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.2 0.600006H48.2C49.1 0.600006 49.8999 1.40001 50.0999 2.30001V8.30001C50.0999 9.20001 49.3 10 48.4 10C47.5 10 46.7 9.20001 46.7 8.30001V6.40001L43.7999 9.30001C45.9 12.1 47.0999 15.5 47.0999 19.1C47.0999 23.4 45.4 27.5 42.2999 30.6C39.2 33.7 35.1 35.4 30.7999 35.4C26.5 35.4 22.4 33.7 19.3 30.6C18.2 29.5 17.2 28.2 16.5 26.8C16.1 26 16.4 24.9 17.2 24.5C18 24.1 19.1 24.4 19.5 25.2C20.1 26.3 20.8 27.3 21.7 28.2C24.1 30.7 27.2999 32 30.7999 32C34.2999 32 37.5 30.6 39.9 28.2C44.9 23.1 44.9 15 39.9 10C37.5 7.50001 34.2999 6.20001 30.7999 6.20001C27.2999 6.20001 24.1 7.60001 21.7 10C19.8 11.8 18.6 14.2 18.1 16.8C17.9 17.7 17 18.4 16.1 18.2C15.2 18 14.5 17.1 14.7 16.2C15.3 12.9 16.8 9.90001 19.2 7.50001C22.3 4.40001 26.4 2.70001 30.7 2.70001C34.5999 2.70001 38.2999 4.10001 41.2999 6.70001L44 4.00001H42.2C41.2999 4.00001 40.5 3.20001 40.5 2.30001C40.5 1.40001 41.2999 0.600006 42.2 0.600006ZM32.2999 38C32.5999 37 33.5 36.5 34.4 36.7C35.3 36.9 35.9 37.9 35.7 38.8C35 41.7 33.5 44.4 31.4 46.5C28.3 49.6 24.2 51.3 19.9 51.3C15.6 51.3 11.5 49.6 8.39995 46.5C5.29995 43.4 3.59995 39.3 3.59995 35C3.59995 31.2 4.89995 27.6 7.19995 24.7L4.79995 22.3L2.99995 24.1C2.69995 24.4 2.19995 24.6 1.79995 24.6C1.39995 24.6 0.899951 24.4 0.599951 24.1C-0.100049 23.5 -0.100049 22.4 0.599951 21.7L2.39995 19.9L1.49995 19C0.799951 18.4 0.799951 17.3 1.49995 16.6C2.09995 15.9 3.19995 15.9 3.89995 16.6L4.79995 17.5L6.59995 15.7C7.19995 15 8.29995 15 8.99995 15.7C9.69995 16.3 9.69995 17.4 8.99995 18.1L7.19995 19.9L9.59995 22.3C12.5 20 16.1 18.7 19.9 18.7C24.2 18.7 28.3 20.4 31.4 23.5C32.5 24.6 33.4 25.8 34.1 27.1C34.5 27.9 34.2 28.9 33.4 29.4C32.6 29.8 31.6 29.5 31.1 28.7C30.6 27.7 29.7999 26.7 29 25.9C26.6 23.4 23.4 22.1 19.9 22.1C16.4 22.1 13.2 23.5 10.8 25.9C8.29995 28.3 6.99995 31.6 6.99995 35C6.99995 38.4 8.39995 41.6 10.7 44.1C13.1 46.6 16.4 47.9 19.8 47.9C23.3 47.9 26.5 46.5 28.9 44.1C30.6 42.4 31.6999 40.3 32.2999 38Z"
      fill={color}
    />
  </svg>
);

export default Gender;
