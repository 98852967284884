import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const PlasticSurgery = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.5 2.69999L35 7.19999L39 11.4C39.6 12 39.6 13 39 13.6C38.7001 13.9 38.3 14.1 37.9 14.1C37.5 14.1 37.1 13.9 36.8 13.6L32.7001 9.49999L30.6 11.6L34.7001 15.7C35.3 16.3 35.3 17.3 34.7001 17.9C34.4001 18.2 34 18.4 33.6 18.4C33.2 18.4 32.8 18.2 32.5 17.9L28.4 13.8L26.3 15.9L30.4 20C31 20.6 31 21.6 30.4 22.2C30.1 22.5 29.7 22.7 29.3 22.7C28.9 22.7 28.5 22.5 28.2 22.2L24.1 18.1L22 20.2L26 24.4C26.6 25 26.6 26 26 26.6C25.7001 26.9 25.3 27.1 24.9 27.1C24.5 27.1 24.1 26.9 23.8 26.6L19.7 22.5L17.6 24.6L21.7 28.7C22.3 29.3 22.3 30.3 21.7 30.9C21.4 31.2 21 31.4 20.6 31.4C20.2 31.4 19.8 31.2 19.5 30.9L15.4 26.8L13.3 28.9L17.4 33C18 33.6 18 34.6 17.4 35.2C17.1 35.5 16.7 35.7 16.3 35.7C15.9 35.7 15.5 35.5 15.2 35.2L11.1 31.1L3.20005 39C2.90005 39.3 2.50005 39.5 2.10005 39.5C1.70005 39.5 1.30005 39.3 1.00005 39C0.400049 38.4 0.400049 37.4 1.00005 36.8L4.60005 33.1L0.500049 29C-0.0999512 28.4 -0.0999512 27.4 0.500049 26.8C1.10005 26.2 2.10005 26.2 2.70005 26.8L6.80005 30.9L8.90005 28.8L4.80005 24.7C4.20005 24.1 4.20005 23.1 4.80005 22.5C5.40005 21.9 6.40005 21.9 7.00005 22.5L11.2 26.5L13.3 24.4L9.20005 20.3C8.60005 19.7 8.60005 18.7 9.20005 18.1C9.80005 17.5 10.8 17.5 11.4 18.1L15.5 22.2L17.6 20.1L13.5 16C12.9 15.4 12.9 14.4 13.5 13.8C14.1 13.2 15.1 13.2 15.7 13.8L19.8 17.9L21.9 15.8L17.8 11.7C17.2 11.1 17.2 10.1 17.8 9.49999C18.4 8.89999 19.4 8.89999 20 9.49999L24.2 13.6L26.3 11.5L22.2 7.39999C21.6 6.79999 21.6 5.79999 22.2 5.19999C22.8 4.59999 23.8 4.59999 24.4 5.19999L28.5 9.29999L30.6 7.19999L26.5 3.09999C25.9 2.49999 25.9 1.49999 26.5 0.899988C27.1 0.299988 28.1 0.299988 28.7 0.899988L32.8 4.99999L37.3 0.499988C37.9 -0.100012 38.9001 -0.100012 39.5 0.499988C40.1 1.09999 40.1 2.09999 39.5 2.69999ZM13.5 38.3C13.5 37.4 12.8 36.7 11.9 36.7C11 36.7 10.3 37.4 10.3 38.3C10.3 39.2 11 39.9 11.9 39.9C12.8 39.9 13.5 39.2 13.5 38.3Z"
      fill={color}
    />
  </svg>
);

export default PlasticSurgery;
