import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Free = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 0C10.7621 0 0 10.7621 0 24C0 37.2379 10.7621 48 24 48C37.2379 48 48 37.2379 48 24C48 10.7621 37.2379 0 24 0ZM4.34526 24C4.34526 19.3516 5.96211 15.1074 8.64 11.7726L13.8947 17.0274C14.6021 15.6632 15.5116 14.4505 16.5726 13.4905L11.7726 8.69053C15.1074 6.01263 19.4021 4.39579 24 4.39579C34.8126 4.39579 43.6547 13.1874 43.6547 24.0505C43.6547 28.6989 42.0379 32.9432 39.36 36.2779L34.7621 31.68C34.8126 31.8821 34.8126 32.0842 34.8126 32.2863C34.8126 33.0947 34.5095 33.8526 33.9537 34.4084C33.4989 34.8632 33.0442 35.2674 32.5389 35.6211L36.2779 39.36C32.8926 42.0379 28.6484 43.6547 24.0505 43.6547C13.1874 43.6547 4.34526 34.8126 4.34526 24Z"
      fill={color}
    />
    <path
      d="M25.1117 16.3705C26.577 16.3705 28.0423 16.9768 29.2044 18.1389C29.457 18.3916 29.8107 18.3916 30.0633 18.1389L32.1854 16.1684C32.337 16.0673 32.3875 15.8652 32.3875 15.7137C32.3875 15.5621 32.337 15.36 32.1854 15.2589C30.2149 13.2884 27.6886 12.1768 25.0612 12.1768C20.716 12.1768 16.7749 15.2084 15.2086 19.7052H12.8844C12.5307 19.7052 12.2275 20.0084 12.2275 20.3621V22.2821C12.2275 22.6358 12.5307 22.9389 12.8844 22.9389H14.5012C14.4507 23.2926 14.4507 23.6463 14.4507 23.9495C14.4507 24.3031 14.4507 24.6063 14.5012 24.96H12.8844C12.5307 24.96 12.2275 25.2631 12.2275 25.6168V27.5368C12.2275 27.8905 12.5307 28.1937 12.8844 28.1937H15.2086C16.7749 32.741 20.716 35.7221 25.0612 35.7221C27.6886 35.7221 30.2149 34.6105 32.1854 32.64C32.2865 32.5389 32.3875 32.3368 32.3875 32.1852C32.3875 32.0337 32.2865 31.8316 32.1854 31.7305L30.0633 29.8105C29.8107 29.5579 29.457 29.6084 29.2044 29.861C28.0423 30.9726 26.6275 31.6294 25.1117 31.6294C22.9896 31.6294 21.0191 30.3663 19.8065 28.2442H25.3139C25.6675 28.2442 25.9707 27.941 25.9707 27.5873V25.6673C25.9707 25.3137 25.6675 25.0105 25.3139 25.0105H18.796C18.7454 24.6568 18.7454 24.3031 18.7454 24C18.7454 23.6968 18.7454 23.3431 18.796 22.9895H25.3139C25.6675 22.9895 25.9707 22.6863 25.9707 22.3326V20.4126C25.9707 20.0589 25.6675 19.7558 25.3139 19.7558H19.857C21.0191 17.6842 23.0402 16.3705 25.1117 16.3705Z"
      fill={color}
    />
  </svg>
);

export default Free;
