import { useEffect, useRef, useState } from 'react';
import Search from 'components/Pages/Search/Search';
import config from 'config';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'hooks/useTranslation';
import Link from './Link';
import CurrencySwitch from './CurrencySwitch';
import Icon from './Icon';
import LanguageSwitch from './LanguageSwitch';
import Logo from './Logo';
import Navigation from './Navigation';
import SlideOut from './SlideOut';
import styles from './Header.module.scss';
import type { AlternateSlug } from 'types/AlternateSlugs';
import type { Brand } from 'types/Brand';

type Props = {
  brand: Brand | null;
  languageSwitchSlugs?: { slugs?: AlternateSlug[] | null; prefix?: string };
  isReduced?: boolean;
  onOpen?: () => void;
  title?: string;
  noMetaTitle?: string;
};

const Header = ({
  brand,
  languageSwitchSlugs,
  isReduced = false,
  onOpen,
}: Props): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { locale, t } = useTranslation();
  const { trackEvent } = useAnalytics();

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [shouldUseSlideOut, setShouldUseSlideOut] = useState<boolean>(false);

  const onTriggerClick = (): void => {
    if (!isMenuOpen) {
      setIsMenuOpen(true);
      onOpen?.();
    } else {
      setIsMenuOpen(false);
    }
  };

  const onMenuClose = (): void => {
    setIsMenuOpen(false);
  };

  const MobileMenuButton = (): JSX.Element => (
    <button
      type="button"
      className={styles.mobileMenuButton}
      aria-label={t('header.menu.open')}
      onClick={onTriggerClick}
      data-cy="openMenu"
    >
      <Icon type={isMenuOpen ? 'Cross' : 'Menu'} />
    </button>
  );

  useEffect(() => {
    setShouldUseSlideOut(window.matchMedia('(max-width: 1023px)').matches);

    window.addEventListener('resize', () => {
      setShouldUseSlideOut(window.matchMedia('(max-width: 1023px)').matches);
    });

    onMenuClose();

    return () => {
      window.removeEventListener('resize', () =>
        setShouldUseSlideOut(window.matchMedia('(max-width: 1023px)').matches),
      );
    };
  }, []);

  const openSearch = (): void => {
    setIsSearchOpen(true);
    trackEvent({
      category: 'SearchBar',
      action: 'Form|Click',
      label: 'Form|SearchBar|TopNav|Click',
    });
  };

  return (
    <header className={styles.header} ref={containerRef}>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <Link href={`/${locale}`}>
            <a className={styles.logo}>{brand && <Logo brand={brand} />}</a>
          </Link>

          <div className={styles.extended}>
            {config.homepage.search.includes(locale) && !isReduced && (
              <div className={styles.search}>
                <label className={styles.searchLabel} onClick={openSearch}>
                  <Icon
                    className={styles.searchSvg}
                    type="Search"
                    color="#585a51"
                  />
                  <span className={styles.searchText}>{t('search')}</span>
                </label>

                <Search
                  open={isSearchOpen}
                  onClose={() => {
                    setIsSearchOpen(false);
                  }}
                  openIdentifier="TopNav"
                />
              </div>
            )}
            {shouldUseSlideOut ? (
              <MobileMenuButton />
            ) : (
              <div className="hidden lg:flex items-center">
                {!isReduced && (
                  <Navigation
                    className="hidden lg:block"
                    onLinkClick={onMenuClose}
                  />
                )}
                <LanguageSwitch
                  // eslint-disable-next-line
                  // @ts-ignore
                  languageSwitchSlugs={languageSwitchSlugs}
                />
                {!isReduced && <CurrencySwitch />}
              </div>
            )}
          </div>
        </div>
      </div>
      <SlideOut
        onClose={onMenuClose}
        open={isMenuOpen}
        placement="top"
        showCloseIcon={false}
        belowHeader
      >
        <div className={styles.mobileWrapper}>
          {!isReduced && <Navigation onLinkClick={onMenuClose} />}
          <hr />
          <div className={styles.mobileLanguage}>
            {/** eslint-disable-next-line
             * @ts-ignore */}
            <LanguageSwitch languageSwitchSlugs={languageSwitchSlugs} />
          </div>
          {!isReduced && (
            <div className={styles.mobileCurrency}>
              <CurrencySwitch />
            </div>
          )}
        </div>
      </SlideOut>
    </header>
  );
};

export default Header;
