import type { IconProps } from 'components/ui/Icon';

const AR = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
    <path d="M0 320h640v160.002H0z" fill="#fff" />
    <path d="M0 160h640v160H0z" fill="#007a3d" />
    <path d="M0 0h640v160H0z" fill="#000" />
    <path d="M 0,0 L 240,240 L 0,480 z" fill="#ce1126" />
  </svg>
);

export default AR;
