import type { IconProps } from 'components/ui/Icon';

const WhatsappAlternate = ({
  color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill={color}
  >
    <path
      d="M11.0027 0H10.9973C4.93213 0 0 4.9335 0 11C0 13.4062 0.7755 15.6365 2.09413 17.4474L0.72325 21.5339L4.95138 20.1823C6.69075 21.3345 8.76562 22 11.0027 22C17.0679 22 22 17.0651 22 11C22 4.93488 17.0679 0 11.0027 0Z"
      fill={color}
    />
    <path
      d="M17.4037 15.5335C17.1384 16.2829 16.0851 16.9044 15.245 17.0859C14.6702 17.2083 13.9195 17.3059 11.3922 16.2581C8.15962 14.9189 6.07788 11.634 5.91563 11.4209C5.76025 11.2077 4.60938 9.6815 4.60938 8.103C4.60938 6.5245 5.411 5.75588 5.73413 5.42588C5.9995 5.155 6.43812 5.03125 6.85887 5.03125C6.995 5.03125 7.11738 5.03813 7.22738 5.04363C7.5505 5.05738 7.71275 5.07663 7.92587 5.58675C8.19125 6.22613 8.8375 7.80463 8.9145 7.96688C8.99287 8.12913 9.07125 8.34913 8.96125 8.56225C8.85813 8.78225 8.76737 8.87987 8.60512 9.06687C8.44287 9.25387 8.28887 9.39687 8.12662 9.59763C7.97812 9.77225 7.81037 9.95925 7.99738 10.2824C8.18438 10.5986 8.83063 11.6532 9.78212 12.5002C11.01 13.5934 12.0055 13.9426 12.3616 14.0911C12.627 14.2011 12.9432 14.175 13.1371 13.9688C13.3832 13.7034 13.6871 13.2634 13.9965 12.8303C14.2165 12.5195 14.4943 12.481 14.7858 12.591C15.0828 12.6941 16.6544 13.471 16.9775 13.6319C17.3006 13.7941 17.5137 13.8711 17.5921 14.0073C17.6691 14.1434 17.6691 14.7828 17.4037 15.5335Z"
      fill="#ffffff"
    />
  </svg>
);

export default WhatsappAlternate;
