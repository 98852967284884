import type { IconProps } from 'components/ui/Icon';

const LocationPinAlternate = ({
  color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.42863 0.00389571C7.51837 0 7.612 0 7.70174 0C11.9505 0 15.4072 3.45159 15.415 7.69012C15.415 8.18098 15.3682 8.67184 15.2746 9.15101C15.2629 9.22503 15.2473 9.30294 15.2239 9.39644C15.1068 9.91847 14.9312 10.4327 14.705 10.9197C13.9169 12.7779 12.1807 15.6685 8.41571 18.789C8.21674 18.9526 7.96704 19.0422 7.70954 19.0422C7.45204 19.0422 7.20234 18.9526 7.00337 18.789C3.2345 15.6646 1.49833 12.7779 0.70632 10.9041C0.483934 10.4288 0.308366 9.91847 0.19132 9.39255C0.148403 9.22503 0.136699 9.13543 0.136699 9.13543C0.000145852 8.42641 -0.0349678 7.68233 0.0352595 6.94215C0.402002 3.12046 3.58174 0.13635 7.42863 0.00389571ZM4.67026 7.77972C4.67026 9.44709 6.02799 10.8067 7.70174 10.8067C9.37549 10.8067 10.7332 9.44709 10.7332 7.77972C10.7332 6.11236 9.37158 4.75276 7.70174 4.75276C6.03189 4.75276 4.67026 6.11236 4.67026 7.77972Z"
      fill={color}
    />
  </svg>
);

export default LocationPinAlternate;
