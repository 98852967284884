import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Cardiology = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="58"
    height="47"
    viewBox="0 0 58 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.9001 16.6C57.9001 18.2 57.6001 19.9 57.1001 21.4C54.6001 32.5 33.4001 46 32.5001 46.5C32.2001 46.7 31.9001 46.8 31.6001 46.8C31.3001 46.8 31.0001 46.7 30.7001 46.5C30.5001 46.3 24.5001 42.6 18.4001 37.4C17.7001 36.8 17.6001 35.7 18.2001 35C18.8001 34.3 19.9001 34.2 20.6001 34.8C25.1001 38.7 29.7001 41.8 31.6001 43C38.0001 38.9 52.0001 28.3 53.7001 20.5C54.2001 19.1 54.4001 17.9 54.4001 16.6C54.4001 12.2 52.0001 8.1 48.1001 6C43.1001 3.3 36.5001 4.5 32.9001 8.9C32.2001 9.7 30.9001 9.7 30.2001 8.9C26.6001 4.5 20.0001 3.2 15.0001 6C11.1001 8.1 8.7001 12.2 8.7001 16.6C8.7001 17.9 8.9001 19.1 9.3001 20.3C9.6001 21.4 10.0001 22.5 10.7001 23.7H20.8001L24.3001 15.8C24.6001 15.1 25.3001 14.7 26.0001 14.8C26.7001 14.9 27.4001 15.4 27.5001 16.1L30.0001 26.8L33.3001 21.4C33.6001 20.9 34.2001 20.6 34.8001 20.6H41.0001C41.9001 20.6 42.7001 21.4 42.7001 22.3C42.7001 23.2 41.9001 24 41.0001 24H35.7001L30.7001 32.1C30.4001 32.6 29.8001 32.9 29.2001 32.9H28.9001C28.2001 32.8 27.7001 32.3 27.5001 31.6L25.2001 21.8L23.4001 26C23.1001 26.6 22.5001 27 21.8001 27H1.8001C0.900098 27 0.100098 26.2 0.100098 25.3C0.100098 24.4 0.900098 23.6 1.8001 23.6H6.9001C6.5001 22.9 6.2001 22 6.0001 21.2C5.5001 19.8 5.3001 18.2 5.3001 16.5C5.3001 10.8 8.4001 5.6 13.4001 2.8C19.2001 -0.4 26.8001 0.6 31.6001 5.1C36.3001 0.7 44.0001 -0.3 49.8001 2.9C54.8001 5.7 57.9001 10.9 57.9001 16.6Z"
      fill={color}
    />
  </svg>
);

export default Cardiology;
