import { Direction } from 'types/Utilities';
import tailwind from 'utils/breakpoints';
import type { IconProps } from 'components/ui/Icon';

const ORIGINAL_WIDTH = 17;
const ORIGINAL_HEIGHT = 16;

const Star = ({
  color = tailwind.theme.backgroundColor.secondary,
  width = ORIGINAL_WIDTH,
  height = ORIGINAL_HEIGHT,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => {
  const w = parseInt(`${width}`);
  const h = parseInt(`${height}`);

  const fullStar = (
    <path
      d="M9.34049 13.3457C8.86693 13.0631 8.27644 13.0631 7.80288 13.3457L4.42587 15.3615C4.04676 15.5877 3.58185 15.2475 3.68286 14.8177L4.56897 11.0473C4.69717 10.5018 4.51063 9.93032 4.08529 9.56551L1.13687 7.03674C0.80025 6.74803 0.978193 6.19653 1.42007 6.159L5.32705 5.82721C5.87935 5.78031 6.36078 5.43264 6.57899 4.9231L8.11206 1.34332C8.28517 0.939109 8.8582 0.939108 9.03131 1.34332L10.5644 4.9231C10.7826 5.43264 11.264 5.78031 11.8163 5.82721L15.7233 6.159C16.1652 6.19653 16.3431 6.74803 16.0065 7.03674L13.0581 9.56551C12.6327 9.93032 12.4462 10.5018 12.5744 11.0473L13.4605 14.8177C13.5615 15.2475 13.0966 15.5877 12.7175 15.3615L9.34049 13.3457Z"
      fill={color}
      stroke={color}
      transform={`scale(${w / ORIGINAL_WIDTH} ${h / ORIGINAL_HEIGHT})`}
    />
  );

  return (
    <svg
      viewBox={`0 0 ${w} ${h}`}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={w}
      height={h}
      {...props}
    >
      <g transform={getRotation(Direction.RIGHT, w, h)}>{fullStar}</g>
    </svg>
  );
};

export default Star;
