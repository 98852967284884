import config from 'config';

export const getContentfulLocale = (locale?: string): string | undefined => {
  if (!locale) {
    return;
  }

  const localeInfo = config.locales.find((l) => l.main === locale);
  if (!localeInfo) {
    return;
  }

  return localeInfo.contentful;
};

export const getPathLocale = (
  contentfulLocale?: string,
): string | undefined => {
  if (!contentfulLocale) {
    return;
  }

  const localeInfo = config.locales.find(
    (l) => l.contentful === contentfulLocale,
  );

  if (!localeInfo) {
    return;
  }

  return localeInfo.main;
};
