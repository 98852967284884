import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Search = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6 13.9999C21.6 18.1973 18.1974 21.5999 14 21.5999C9.80266 21.5999 6.40002 18.1973 6.40002 13.9999C6.40002 9.80254 9.80266 6.3999 14 6.3999C18.1974 6.3999 21.6 9.80254 21.6 13.9999ZM20.4676 22.3988C18.6779 23.779 16.4348 24.5999 14 24.5999C8.14581 24.5999 3.40002 19.8541 3.40002 13.9999C3.40002 8.14568 8.14581 3.3999 14 3.3999C19.8542 3.3999 24.6 8.14568 24.6 13.9999C24.6 16.3373 23.8435 18.498 22.5619 20.2505L23.0506 20.7392L29.0607 26.7493C29.6465 27.3351 29.6465 28.2848 29.0607 28.8706C28.4749 29.4564 27.5252 29.4564 26.9394 28.8706L20.9293 22.8606L20.4676 22.3988Z"
      fill={color}
    />
  </svg>
);

export default Search;
