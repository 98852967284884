import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Schedule = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <rect
        x="7.11523"
        y="17.5001"
        width="3.33333"
        height="3.33333"
        rx="1.66667"
        fill={color}
      />
      <rect
        x="7.11523"
        y="26.6667"
        width="3.33333"
        height="3.33333"
        rx="1.66667"
        fill={color}
      />
      <path
        d="M36.2819 19.1667V8.41675C36.2819 6.75989 34.9388 5.41675 33.2819 5.41675H29.6152"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M7.94824 5.41675H4.69824C3.04139 5.41675 1.69824 6.75989 1.69824 8.41675V32.0001C1.69824 33.6569 3.04139 35.0001 4.69824 35.0001H19.6149"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path d="M13.3652 5.41675H24.6152" stroke={color} strokeWidth="3" />
      <rect
        x="25.2817"
        y="2.33337"
        width="3.66667"
        height="5.33333"
        rx="1.83333"
        stroke={color}
        strokeWidth="3"
      />
      <rect
        x="8.61523"
        y="2.33337"
        width="3.66667"
        height="5.33333"
        rx="1.83333"
        stroke={color}
        strokeWidth="3"
      />
      <line
        x1="2.78174"
        y1="12.6667"
        x2="25.6151"
        y2="12.6667"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="32.7817"
        y1="12.6667"
        x2="34.7817"
        y2="12.6667"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M38.9486 30C38.9486 34.2342 35.5161 37.6667 31.2819 37.6667C27.0477 37.6667 23.6152 34.2342 23.6152 30C23.6152 25.7659 27.0477 22.3334 31.2819 22.3334C35.5161 22.3334 38.9486 25.7659 38.9486 30Z"
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M27.5317 30L30.0317 32.5L34.6151 27.9167"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="17.1152"
        y="17.5001"
        width="3.33333"
        height="3.33333"
        rx="1.66667"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(0.448242)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Schedule;
