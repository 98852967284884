import { Direction } from 'types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Angle = ({
  color = iconDefault.color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    height="16px"
    width="16px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 25"
    x="0px"
    y="0px"
  >
    <path
      fill={color || 'currentColor'}
      transform={`${getRotation(
        Direction.RIGHT,
        14,
        25,
      )} translate(-22 -17.757)`}
      fillRule="evenodd"
      d="M24.9359726,42.1545706 C24.2642083,42.8266611 23.1750576,42.8266611 22.5036194,42.1545706 C21.8321269,41.4830781 21.8321269,40.3939817 22.5036194,39.7225435 L31.847878,30.3785568 L22.5038912,21.0348961 C21.8323986,20.3631318 21.8323986,19.2741442 22.5038912,18.6026516 C23.1753837,17.9308873 24.2644801,17.9308873 24.9362444,18.6026516 L35.4965708,29.162815 C35.8323171,29.4987243 36,29.9385047 36,30.3785024 C36,30.8187176 35.831991,31.258824 35.4962991,31.5945703 L24.9359726,42.1545706 Z"
    />
  </svg>
);

export default Angle;
