import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Headset = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="31"
    height="32"
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.6304 11.1043C27.6304 5.24772 22.1995 0.5 15.5 0.5C8.80055 0.5 3.36957 5.24772 3.36957 11.1043C1.63761 11.2929 0 12.2826 0 14.5802V20.118C0 22.7102 2.07565 23.6528 4.04348 23.6528H5.99783C6.36999 23.6528 6.67174 23.389 6.67174 23.0637V11.6581C6.67174 11.3328 6.36999 11.069 5.99783 11.069H4.71739C4.71739 5.86314 9.54497 1.64291 15.5 1.64291C21.455 1.64291 26.2826 5.86314 26.2826 11.069H25.0022C24.63 11.069 24.3283 11.3328 24.3283 11.6581V23.046C24.3283 23.3713 24.63 23.6351 25.0022 23.6351H26.2826C26.0939 27.6824 23.87 28.6309 19.4289 28.8018V28.4071C19.4289 27.7563 18.8255 27.2288 18.0811 27.2288H13.8422C13.0978 27.2288 12.4943 27.7563 12.4943 28.4071V30.3217C12.4943 30.9725 13.0978 31.5 13.8422 31.5H18.0946C18.839 31.5 19.4424 30.9725 19.4424 30.3217V29.98C23.715 29.8092 27.408 29.0374 27.6304 23.5998C29.3624 23.4113 31 22.4215 31 20.1239V14.5861C31 12.2767 29.3691 11.2929 27.6304 11.1043ZM5.32391 12.206V22.4569H4.00978C3.01239 22.4569 1.31413 22.1505 1.31413 20.1003V14.5625C1.31413 12.53 2.99217 12.206 4.00978 12.206H5.32391ZM18.0946 29.4322V30.3276H13.8422V28.413H18.0946V29.4322ZM29.6522 20.118C29.6522 22.1505 27.9741 22.4745 26.9565 22.4745H25.6761V12.2472H26.9902C27.9876 12.2472 29.6859 12.5536 29.6859 14.6038L29.6522 20.118Z"
      fill={color}
    />
    <path
      opacity="0.5"
      d="M0.540039 15.125C0.540039 13.4681 1.88318 12.125 3.54004 12.125H6.08087V22.7812H3.54004C1.88318 22.7812 0.540039 21.4381 0.540039 19.7812V15.125Z"
      fill={color}
    />
    <path
      opacity="0.5"
      d="M30.4609 15.125C30.4609 13.4681 29.1178 12.125 27.4609 12.125H24.9201V22.7812H27.4609C29.1178 22.7812 30.4609 21.4381 30.4609 19.7812V15.125Z"
      fill={color}
    />
  </svg>
);

export default Headset;
