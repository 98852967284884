import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Quote = ({
  color = iconDefault.color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 3.70711V16.5C14 16.6326 13.9473 16.7598 13.8536 16.8536C13.7598 16.9473 13.6326 17 13.5 17H1.5C1.36739 17 1.24021 16.9473 1.14645 16.8536C1.05268 16.7598 1 16.6326 1 16.5V1.5C1 1.36739 1.05268 1.24021 1.14645 1.14645C1.24021 1.05268 1.36739 1 1.5 1H11.2929L14 3.70711Z"
      stroke={color}
      strokeLinejoin="round"
    />
    <rect x="6" y="5" width="6" height="1" fill={color} />
    <rect x="6" y="9" width="6" height="1" fill={color} />
    <rect x="6" y="13" width="6" height="1" fill={color} />
    <rect x="2.5" y="4.5" width="2" height="2" fill={color} />
    <rect x="2.5" y="8.5" width="2" height="2" fill={color} />
    <rect x="2.5" y="12.5" width="2" height="2" fill={color} />
  </svg>
);

export default Quote;
