import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Bone = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5001 53.7C11.2001 53.7 7.6001 50.5 6.8001 46.4C3.0001 45.6 0.100098 42.1 0.100098 38.1C0.100098 33.4 3.9001 29.6 8.6001 29.6C10.5001 29.6 12.2001 30.2 13.7001 31.3L31.2001 13.8C30.1001 12.3 29.5001 10.5 29.5001 8.7C29.5001 4 33.3001 0.199997 38.0001 0.199997C42.1001 0.199997 45.5001 3 46.3001 6.9C50.4001 7.6 53.6001 11.2 53.6001 15.6C53.6001 20.4 49.7001 24.4 44.8001 24.4C42.9001 24.4 41.2001 23.8 39.7001 22.8L37.1001 25.4C36.5001 26 35.5001 26 34.9001 25.4C34.3001 24.8 34.3001 23.8 34.9001 23.2L38.5001 19.6C38.8001 19.3 39.2001 19.1 39.6001 19.1C40.0001 19.1 40.4001 19.3 40.7001 19.6C41.8001 20.7 43.2001 21.3 44.7001 21.3C47.8001 21.3 50.3001 18.8 50.3001 15.7C50.3001 12.6 47.8001 10.1 44.7001 10.1C43.9001 10.1 43.2001 9.4 43.2001 8.6C43.0001 5.8 40.7001 3.5 37.8001 3.5C34.8001 3.5 32.4001 5.9 32.4001 8.9C32.4001 10.4 33.1001 11.9 34.2001 12.9C34.5001 13.2 34.7001 13.6 34.7001 14C34.7001 14.4 34.5001 14.8 34.2001 15.2L15.0001 34.6C14.7001 34.9 14.3001 35.1 13.8001 35.1C13.4001 35.1 13.0001 34.9 12.7001 34.6C11.7001 33.5 10.2001 32.8 8.7001 32.8C5.7001 32.8 3.3001 35.2 3.3001 38.2C3.3001 41 5.5001 43.4 8.4001 43.6C9.2001 43.6 9.9001 44.3 9.9001 45.1C9.9001 48.2 12.5001 50.7 15.5001 50.7C18.6001 50.7 21.1001 48.2 21.1001 45.1C21.1001 43.6 20.5001 42.1 19.4001 41.1C19.1001 40.8 18.9001 40.4 18.9001 40C18.9001 39.6 19.1001 39.2 19.4001 38.9L29.1001 29.2C29.7001 28.6 30.7001 28.6 31.3001 29.2C31.9001 29.8 31.9001 30.8 31.3001 31.4L22.6001 40.1C23.7001 41.6 24.2001 43.3 24.2001 45.2C24.3001 49.8 20.4001 53.7 15.5001 53.7Z"
      fill={color}
    />
  </svg>
);

export default Bone;
