import type { IconProps } from 'components/ui/Icon';

const VerifiedClinic = ({
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="17"
    height="27"
    viewBox="0 0 17 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 24.6114V1C0 0.447715 0.447715 0 1 0H16C16.5523 0 17 0.447715 17 0.999999V24.6114C17 25.4987 15.9304 25.9465 15.2983 25.3239L9.20173 19.3191C8.81246 18.9357 8.18754 18.9357 7.79827 19.3191L1.70173 25.3239C1.0696 25.9465 0 25.4987 0 24.6114Z"
      fill="#FFCA58"
    />
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.93506 9.94953C7.04619 9.82022 7.11342 9.6529 7.11342 9.47063V8.08757V8.08757H8.50353C8.55469 8.08757 8.60464 8.08241 8.65304 8.07244C8.74982 8.05249 8.83987 8.01397 8.91903 7.96067C9.11727 7.82706 9.24811 7.60128 9.24811 7.34661V5.86485C9.24811 5.61018 9.11727 5.3844 8.91903 5.25096C8.83987 5.19765 8.74982 5.15896 8.65304 5.13901C8.60464 5.12921 8.55469 5.12405 8.50353 5.12405H7.79785C7.54914 5.29102 7.32186 5.48723 7.12051 5.70733C6.5168 6.36765 6.14832 7.24464 6.14832 8.20554C6.14832 8.47052 6.17631 8.72897 6.22955 8.97849C6.26515 9.1446 6.31182 9.30692 6.36885 9.46409C6.42606 9.62144 6.49347 9.77379 6.57055 9.92047C6.60892 9.9939 6.64988 10.0658 6.69309 10.1361C6.72403 10.121 6.75393 10.1038 6.78227 10.0849C6.83914 10.0467 6.89064 10.0011 6.93506 9.94953"
      fill="#155263"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5581 10.7974C12.4645 10.8932 12.3656 10.9838 12.2621 11.0691L11.4057 9.91629C11.2387 9.69137 10.9198 9.64373 10.6936 9.81019C10.5524 9.91388 10.4807 10.0769 10.4877 10.2392C10.4905 10.3042 10.5057 10.3692 10.5342 10.4301C10.5486 10.4607 10.566 10.4903 10.587 10.5185L11.3932 11.6037C10.9312 11.8076 10.4207 11.922 9.88369 11.922C8.53402 11.922 7.34941 11.2061 6.69298 10.136C6.64977 10.0657 6.60898 9.99384 6.57044 9.92041C6.49336 9.77373 6.42595 9.62138 6.36874 9.46404C6.31171 9.30687 6.26504 9.14471 6.22961 8.97843C6.17638 8.72909 6.14838 8.47047 6.14838 8.20565C6.14838 7.24458 6.51669 6.36777 7.12058 5.70728C7.32175 5.48717 7.54921 5.29114 7.79774 5.124C8.39385 4.72334 9.11181 4.48913 9.88369 4.48913C11.9434 4.48913 13.619 6.15643 13.619 8.20565C13.619 8.56522 13.5673 8.91309 13.471 9.24238C13.4327 9.37393 13.3869 9.50273 13.3347 9.62774C13.2821 9.7531 13.2232 9.87484 13.1579 9.99281C13.0925 10.1108 13.0212 10.2248 12.9437 10.3345C12.905 10.3893 12.8649 10.443 12.8234 10.4956C12.7405 10.6009 12.6518 10.7016 12.5581 10.7974V10.7974ZM12.8672 11.8836C13.9456 11.0161 14.637 9.68991 14.637 8.20575C14.637 5.59784 12.5046 3.47622 9.88369 3.47622C8.85048 3.47622 7.89401 3.8069 7.11349 4.3661V3.74104C7.11349 3.3335 6.77836 3.00024 6.36875 3.00024H4.87943C4.46981 3.00024 4.13468 3.3335 4.13468 3.74104V5.12409H2.74457C2.33513 5.12409 2 5.45752 2 5.86489V7.34682C2 7.75419 2.33513 8.08761 2.74457 8.08761H4.13468V9.47067C4.13468 9.87804 4.46981 10.2116 4.87943 10.2116H5.5801C6.34023 11.8189 7.98302 12.9351 9.88369 12.9351C10.6476 12.9351 11.3694 12.7537 12.0097 12.4338L13.9628 15.0627C14.0627 15.197 14.2165 15.268 14.3726 15.268C14.4776 15.268 14.5838 15.2359 14.6747 15.169C14.9007 15.0027 14.9486 14.6855 14.7815 14.4607L12.8672 11.8836Z"
      fill="#155263"
    />
  </svg>
);

export default VerifiedClinic;
