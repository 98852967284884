import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { CSSTransition } from 'react-transition-group';
import Button from 'components/Qds/Button';
import Icon from 'components/ui/Icon';
import { generateScopedTransitionClasses } from 'utils/transitions';
import { useTranslation } from 'hooks/useTranslation';
import styles from './SlideOut.module.scss';
import type { ReactNode } from 'react';

type Props = {
  activeSlide?: number;
  backdrop?: boolean;
  belowHeader?: boolean;
  children: ReactNode;
  currentProgress?: number;
  footer?: string | ReactNode;
  isMultiStep?: boolean;
  lazyLoad?: boolean;
  onClose?: () => void;
  onStepBack?: () => void;
  open: boolean;
  placement?: 'bottom' | 'left' | 'right' | 'top';
  roundedCorners?: boolean;
  showCloseIcon?: boolean;
  showCloseButton?: boolean;
  showPrevIcon?: boolean;
  showProgressBar?: boolean;
  title?: string;
  visuallyHidden?: boolean;
  className?: string;
};

const SlideOut = forwardRef<HTMLElement, Props>(
  (
    {
      backdrop = true,
      children,
      isMultiStep = false,
      onClose,
      onStepBack,
      open = false,
      placement = 'left',
      showPrevIcon = false,
      showCloseIcon = true,
      showCloseButton = false,
      visuallyHidden = false,
      lazyLoad = true,
      belowHeader = false,
      title,
      footer,
      activeSlide,
      showProgressBar = false,
      currentProgress = 0,
      roundedCorners = false,
      className,
      ...props
    },
    ref,
  ): JSX.Element => {
    const [isOpen, setIsOpen] = useState(open);
    const [showContent, setShowContent] = useState(false);
    const [progress] = useState(currentProgress);
    const { t } = useTranslation();

    useEffect(() => {
      setIsOpen(open);
      if (true === open) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    }, [open]);

    const handleClose = (): void => {
      setIsOpen(false);

      document.body.classList.remove('overflow-hidden');
      onClose && onClose();
    };

    const handleArrowClick = (): void => {
      // eslint-disable-next-line
      // @ts-ignore
      if (isMultiStep && activeSlide > 0) {
        onStepBack?.();
      } else {
        setIsOpen(false);
        if (document.querySelectorAll('.SlideOutContent.isOpen').length === 1) {
          document.body.classList.remove('overflow-hidden');
          document.body.classList.remove('fixed');
          document.documentElement.classList.remove('overflow-hidden');
        }

        onClose?.();
      }
    };

    const overlayTransitionClasses = generateScopedTransitionClasses(
      'fade',
      styles,
    );

    return (
      <CSSTransition
        in={visuallyHidden ? true : isOpen}
        classNames={overlayTransitionClasses}
        mountOnEnter
        unmountOnExit
        onExiting={() => setShowContent(false)}
        onEntered={() => setShowContent(true)}
        timeout={{
          exit: 500,
        }}
      >
        <>
          {backdrop && (
            <div
              className={clsx(
                'fixed w-full h-screen top-0 bg-black opacity-75 left-0',
                belowHeader ? 'z-20' : ['z-50', styles.SlideOutBackdrop],
              )}
              onClick={handleClose}
            />
          )}
          <div
            {...props}
            className={clsx(
              'bg-white w-full md:w-2/5',
              styles.SlideOutContent,
              placement === 'right' && styles.rightPlacement,
              placement === 'left' && styles.leftPlacement,
              placement === 'top' && styles.topPlacement,
              placement === 'bottom' && styles.bottomPlacement,
              (showContent || (visuallyHidden && isOpen)) && styles.isOpen,
              roundedCorners && 'rounded-t-xl',
              className,
            )}
            // eslint-disable-next-line
            // @ts-ignore
            ref={ref}
            style={{ zIndex: 51 }}
          >
            {isMultiStep && showProgressBar && (
              <div className="h-2 w-full bg-bluishGrey">
                <div
                  className="bg-secondary h-2 rounded-r transition-all duration-300 ease-in-out"
                  style={{ width: `${progress || currentProgress}%` }}
                />
              </div>
            )}
            {showPrevIcon && (
              <button
                className="left-0 absolute z-10 ml-2 mt-4 bg-transparent border-0 flex justify-center items-center"
                onClick={handleArrowClick}
                data-cy="crossBtn"
                title="Back"
                style={{ width: '40px', height: '40px' }}
              >
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.6632 2.11829C8.15242 1.59293 8.10285 0.788478 7.55247 0.321492C7.0021 -0.145494 6.15933 -0.0981728 5.67011 0.427186L0.336787 6.15445C-0.112263 6.63667 -0.112263 7.36333 0.336787 7.84555L5.67011 13.5728C6.15933 14.0982 7.0021 14.1455 7.55247 13.6785C8.10285 13.2115 8.15242 12.4071 7.6632 11.8817L3.11727 7L7.6632 2.11829Z"
                    fill="#585A51"
                  />
                </svg>
              </button>
            )}
            {showCloseIcon && (
              <button
                className={clsx(styles.closeBtn, 'right-0 absolute z-10')}
                onClick={handleClose}
                data-cy="crossBtn"
                title={t('close')}
              >
                <Icon type="Cross" className="w-5 opacity-75" />
              </button>
            )}
            {title && (
              <h2 className="pt-1 text-center font-bold font-adelle text-2xl text-navy tracking-normal mt-14">
                {title}
              </h2>
            )}
            {lazyLoad ? <LazyLoad once>{children}</LazyLoad> : children}
            {showCloseButton && !footer ? (
              <div className="py-8 px-6">
                <Button onClick={handleClose} variant="secondary" fullWidth>
                  {t('close')}
                </Button>
              </div>
            ) : null}
            {footer && <div className="py-8 px-6">{footer}</div>}
          </div>
        </>
      </CSSTransition>
    );
  },
);

SlideOut.displayName = 'SlideOut';
export default SlideOut;
