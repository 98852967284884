import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Plus = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33333 1.66668C8.33333 0.930297 7.73638 0.333344 7 0.333344C6.26362 0.333344 5.66667 0.930296 5.66667 1.66668V5.66668H1.66667C0.930286 5.66668 0.333332 6.26363 0.333332 7.00001C0.333332 7.73639 0.930285 8.33334 1.66666 8.33334H5.66667V12.3333C5.66667 13.0697 6.26362 13.6667 7 13.6667C7.73638 13.6667 8.33333 13.0697 8.33333 12.3333V8.33334H12.3333C13.0697 8.33334 13.6667 7.73639 13.6667 7.00001C13.6667 6.26363 13.0697 5.66668 12.3333 5.66668H8.33333V1.66668Z"
      fill={color}
    />
  </svg>
);

export default Plus;
