import { Direction } from 'types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Filters = ({
  color = iconDefault.color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    className={className}
    {...props}
    fill={color || 'currentColor'}
    transform={getRotation(Direction.RIGHT, 18, 20)}
    width="18"
    height="20"
    viewBox="0 0 18 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3346 2.9138C12.5635 1.57253 13.7238 0.550049 15.1246 0.550049C16.6846 0.550049 17.9535 1.82912 17.9535 3.39589C17.9535 4.96265 16.6846 6.23395 15.1207 6.23395C13.7199 6.23395 12.5597 5.20758 12.3307 3.87019H0.627318C0.363448 3.87019 0.150024 3.65637 0.150024 3.392C0.150024 3.12763 0.363448 2.9138 0.627318 2.9138H12.3346ZM13.2426 3.38811C13.2426 4.42614 14.0885 5.27367 15.1246 5.27367C16.1607 5.27367 17.0066 4.42614 17.0066 3.38811C17.0066 2.35008 16.1607 1.50255 15.1246 1.50255C14.0885 1.50255 13.2426 2.35008 13.2426 3.38811ZM17.4839 9.56964H5.77665C5.54771 8.23225 4.38746 7.20589 2.98662 7.20589C1.42281 7.20589 0.153905 8.47718 0.153905 10.0439C0.153905 11.6107 1.42669 12.882 2.98662 12.882C4.38746 12.882 5.54771 11.8595 5.77665 10.5183H17.48C17.7439 10.5183 17.9573 10.3044 17.9573 10.0401C17.9573 9.78347 17.7439 9.56964 17.4839 9.56964ZM1.10461 10.0478C1.10461 11.0859 1.95055 11.9334 2.98662 11.9334C4.02658 11.9334 4.86863 11.0859 4.86863 10.0478C4.86863 9.0098 4.0227 8.16227 2.98662 8.16227C1.95055 8.16227 1.10461 9.0098 1.10461 10.0478ZM15.1246 13.9162C13.7238 13.9162 12.5635 14.9386 12.3346 16.2799H0.627318C0.363448 16.2799 0.150024 16.4937 0.150024 16.7581C0.150024 17.0225 0.363448 17.2363 0.627318 17.2363H12.3307C12.5597 18.5737 13.7199 19.6 15.1207 19.6C16.6846 19.6 17.9535 18.3288 17.9535 16.762C17.9535 15.1952 16.6846 13.9162 15.1246 13.9162ZM13.2426 16.7542C13.2426 17.7922 14.0885 18.6398 15.1246 18.6398C16.1607 18.6398 17.0066 17.7922 17.0066 16.7542C17.0066 15.7162 16.1607 14.8687 15.1246 14.8687C14.0885 14.8687 13.2426 15.7162 13.2426 16.7542Z"
    />
  </svg>
);

export default Filters;
