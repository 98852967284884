import clsx from 'clsx';
import Cookie from 'js-cookie';
import { useContext, useState } from 'react';
import config from 'config';
import { CurrencyContext } from 'context/currency';
import { Direction } from 'types/Utilities';
import Icon from './Icon';
import styles from './CurrencySwitch.module.scss';

const CurrencySwitch = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { currency, setCurrency } = useContext(CurrencyContext);

  return (
    <div
      onMouseOver={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
      className={styles.container}
    >
      <span className="mr-2 text-lg lg:text-base">
        {currency} -{' '}
        {config.currencies[currency as keyof typeof config.currencies]?.symbol}
      </span>
      <Icon
        type="Angle"
        direction={Direction.BOTTOM}
        className="text-primary mr-2 w-5 md:mr-1 md:w-3"
      />
      <div className={clsx(styles.selectContainer, !isOpen && 'hidden')}>
        <ul className={styles.select}>
          {Object.values(config.currencies).map((c, i) => (
            <li key={i}>
              <span
                className="flex items-center normal-case hover:bg-light-grey text-base p-3 cursor-pointer"
                onClick={() => {
                  Cookie.set('qm_currency', c.name);
                  setCurrency(c.name);
                }}
              >
                {c.name} - {c.symbol}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CurrencySwitch;
