import type { ImageProps } from 'next/image';

export type ContentfulImageConfig = {
  fit?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';
  focus?:
    | 'center'
    | 'top'
    | 'left'
    | 'bottom'
    | 'top_right'
    | 'top_left'
    | 'bottom_right'
    | 'bottom_left'
    | 'face'
    | 'faces';
};

export type ContentfulLoaderArgs = {
  src: ImageProps['src'];
  width?: string | number | undefined;
  quality?: string | number | undefined;
};

type ContentfulLoader = (args: ContentfulLoaderArgs) => string;

export const createContentfulLoader = ({
  fit,
  focus,
}: ContentfulImageConfig): ContentfulLoader => {
  return ({
    src: _src,
    width: _width,
    quality,
  }: ContentfulLoaderArgs): string => {
    let src = _src;
    let width = _width;

    let suffix = '';

    // -- we need an absolute url
    if (/^\/\/images\.ctfassets\.net/.test(src as string)) {
      src = `https:${src}`;
    }

    if (fit) {
      suffix += `&fit=${fit}`;
    }

    // -- Focus only works with pad, fill, crop and thumb as per
    // -- https://www.contentful.com/developers/docs/references/images-api/#/reference/resizing-&-cropping/specify-focus-area
    if (['pad', 'fill', 'crop', 'thumb'].includes(fit as string) && focus) {
      suffix += `&f=${focus}`;
    }

    if (isNaN(width as number)) {
      return `${src}?q=${quality}${suffix}&fm=webp`;
    }

    // -- prevent return of images larger than 4000 which is not supported by contentful
    if (width && (width as number) >= 2000) {
      width = 1700;
    }

    return `${src}?w=${(width as number) + 200}&q=${quality}${suffix}&fm=webp`;
  };
};

/**
 * Convert block id into a valid and useable id
 */
export const sanitizeContentfulBlockId = (id?: string): string => {
  const ID_REPLACE_REGEX = /[^a-zA-Z_]/g;
  return id ? id.replace(ID_REPLACE_REGEX, '') : '';
};
